<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  watchers: {
    type: Array,
    default: () => [],
  },
  asset_id: {
    type: String,
    default: '',
  },
  viewed: {
    type: Object,
    default: () => {},
  },
  on_update: {
    type: Function,
    default: () => {
      return true;
    },
  },
  module: {
    type: String,
    default: 'task',
  },
});
const emit = defineEmits(['close']);

const auth_store = useAuthStore();
const common_store = useCommonStore();

const form$ = ref(null);
const loading = ref(false);

const viewing_history = computed(() => {
  const history = {};
  // Filtering unavailable users
  Object.keys(props.viewed).forEach((uid) => {
    if (common_store.get_user(uid) || common_store?.internal_users_map?.[uid])
      history[uid] = props.viewed[uid];
  });
  // Sort in descending order
  return Object.fromEntries(
    Object.entries(history).sort(([, dateA], [, dateB]) => new Date(dateB) - new Date(dateA)),
  );
});

function onFormMounted(el$) {
  if (props.watchers.length) {
    const to_me = props.watchers.filter(obj => obj.uid === auth_store.logged_in_user_details.user_id).length;
    if (to_me) {
      el$?.load({
        watchers_group: {
          watchers: props.watchers.filter(obj => obj.uid !== auth_store.logged_in_user_details.user_id).map(obj => obj.uid),
          to_me: true,
        },
      }, true);
    }
    else {
      el$?.load({
        watchers_group: {
          watchers: props.watchers.map(obj => obj.uid),
          to_me: false,
        },
      }, true);
    }
  }
};

async function handleSave() {
  loading.value = true;
  const form_data = form$.value.data;
  const watchers = form_data.watchers_group.watchers.map((u) => {
    return { uid: u.uid };
  });
  if (form_data.watchers_group.to_me)
    watchers.push({ uid: auth_store.logged_in_user_details.user_id });
  await props.on_update({ watchers });
  loading.value = false;
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :sync="true"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :display-errors="false"
      class="grid grid-rows-1 grid-flow-col gap-4"
      :endpoint="handleSave"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="">
              <p>{{ $t('Watchers') }}</p>
              <p class="text-sm text-gray-700 font-normal">
                {{ $t(`Select the members to receive notifications for all ${module} updates`) }}
              </p>
            </div>
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content :is_scroll="false">
          <ObjectElement name="watchers_group" :label="$t('Send notifications')">
            <CheckboxElement name="to_me">
              {{ $t('To me') }}
            </CheckboxElement>
            <hawk-assignee-input
              class="mb-6"
              :options="{
                name: 'watchers',
                placeholder: $t('Select Watchers'),
                canClear: false,
                has_teams: true,
                overrideClasses: {
                  TagsElement: {
                    select: {
                      container_disabled: '',
                    },
                  },
                },
              }"
              :asset_id="asset_id"
              :multi="true"
              :format_load="true"
            />
          </ObjectElement>
          <div v-if="Object.keys(viewing_history).length">
            <p class="text-md font-semibold text-gray-700 mb-3">
              {{ $t('History') }}
            </p>
            <div class="max-h-[250px] overflow-scroll scrollbar">
              <div v-for="(value, key) in viewing_history" :key="key" class="flex items-center text-xs mb-2.5">
                <HawkMembers :members="key" type="badge" class="mr-1" />
                <p class="text-gray-600 mr-1">
                  {{ $t(`viewed the ${module} on`) }}
                </p>
                <p class="text-black">
                  {{ $date(value, 'MMM DD, YYYY hh:mm A') }}
                </p>
              </div>
            </div>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template v-if="$slots.left_footer" #left>
            <slot name="left_footer" />
          </template>
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button class="mr-5" type="outlined" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :loading="loading" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
