export function formatNumber(value) {
  return Number.isInteger(value) ? value : Number.parseFloat(value).toFixed(2);
}

export function formatPercentageValue(value) {
  return value ? `${formatNumber(Number.parseInt(value * 100)) || 0}%` : '-';
}

export function formatPlannedActualValue(field, value) {
  const { prefix, suffix } = getPrefixSuffix(field);
  const actualValue = value?.actual === null
    ? '-'
    : `${prefix}${formatNumber(value?.actual)}${suffix}`;
  const plannedValue = value?.planned === null
    ? '-'
    : `${prefix}${formatNumber(value?.planned)}${suffix}`;
  return `${actualValue} / ${plannedValue}`;
}

export function formatDropdownRadioValue(field, value) {
  return field?.config?.find(item => item.uid === value)?.name || '-';
}

export function formatNumberValue(field, value) {
  const { prefix, suffix } = getPrefixSuffix(field);
  return value ? `${prefix}${formatNumber(value) || 0}${suffix}` : '-';
}
export function formatMoneyValue(field, value) {
  return value ? `${getPrefixSuffix(field)?.currency}${formatNumber(value) || 0}` : '-';
}

export function formatLabelsCheckboxValue(field, value, value_separator = ', ') {
  const values = value.map((val) => {
    return field.config.find(item => item.uid === val)?.name;
  });

  return values.join(value_separator) || '-';
}

export function getPrefixSuffix(field) {
  const prefix = field?.properties?.prefix?.length ? `${field?.properties?.prefix} ` : '';
  const suffix = field?.properties?.suffix?.length ? ` ${field?.properties?.suffix}` : '';
  const currency = field?.properties?.currency?.length ? `${field?.properties?.currency} ` : '';
  return { prefix, suffix, currency };
};
