<script setup>
import { isEqual, keyBy, uniqBy } from 'lodash-es';
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useMapItemCreation } from '~/common/composables/mapbox/creation-icons';
import { useMap } from '~/common/composables/mapbox/maps';
import { useMapboxFeatureSelection } from '~/common/composables/mapbox/selection';
import useEmitter from '~/common/composables/useEmitter';
import { useCustomViewStore } from '~/common/stores/custom-view.store.js';
import { usePusherStore } from '~/common/stores/pusher.store.js';
import TerraNewForm from '~/forms/components/new-form/terra-new-form.vue';
import { useFormPermissions } from '~/forms/composables/form-permissions.composable';
import { useFormsStore } from '~/forms/store/forms.store';
import TerraTaskForm from '~/tasks/components/molecule/task-form/terra-task-form.vue';
import { useTasksStore } from '~/tasks/store/tasks.store';
import ConfirmLocation from '~/terra/components/confirm-location.vue';
import CreateItems from '~/terra/components/create-shortcut.vue';
import FeatureDetails from '~/terra/components/feature-details/feature-details.vue';
import Elevation from '~/terra/components/map-tools/elevation.vue';
import Scale from '~/terra/components/map-tools/scale.vue';
import Volume from '~/terra/components/map-tools/volume.vue';
import MbTilesPopup from '~/terra/components/mb-tiles-popup.vue';
import TerraSidebarMenu from '~/terra/components/terra-sidebar-menu.vue';
import YieldEstimator from '~/terra/components/yield-estimator.vue';
import RestoreView from '~/terra/molecules/restore-view.vue';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { useThermHelperComposable } from '~/therm/composables/helper-composable.js';
import MapTools from '../components/map-tools.vue';
import { useTerraHelperComposable } from '../utils/helper-composable';

const task_form_centroid = ref(null);
const marker_location = ref(null);
const terra_store = useTerraStore();
const emitter = useEmitter();
const forms_store = useFormsStore('terra_form_store');
const custom_view_store = useCustomViewStore();
const auth_store = useAuthStore();

const route = useRoute();
const router = useRouter();
const $t = inject('$t');
const $toast = inject('$toast');
const task_store = useTasksStore('terra_task_store');

watchEffect(() => {
  const pusher_store = usePusherStore();
  if (pusher_store.is_initialized) {
    task_store.subscribe_pusher({
      organization: auth_store.current_organization?.uid,
      ...(route.params.asset_id ? { asset: route.params.asset_id } : {}),
    });
  }
}, { flush: 'post' });

// General
const active_tool = ref('');
const filters_active = ref(false);

const create_enable = ref(false);

const settings_active = ref(false);

// Task/form icon on map
const open_task_popup = ref(false);
const open_form_popup = ref(false);
const task_marker_active = ref(false);
const form_marker_active = ref(false);
const project_details_for_task_form_creation = ref(null);

// Yield estimator
const yield_estimator_marker = ref(null);
const is_yield_estimator_active = ref(false);
const yield_estimator_coordinates = ref([]);

// Tools
const guide_enable = ref(false);
const visualizer_enable = ref(false);
const scale_enable = ref(false);

const { loadTaskFormIcons, addSymbols, dragLayers } = useTerraHelperComposable();
const { flyToAssociatedFeature } = useThermHelperComposable();
const { syncAndGetButtonState } = useFormPermissions();
provide('$form_create_permission', syncAndGetButtonState());

// Map style
const map_style = ref(localStorage.getItem('map_style') || 'mapbox://styles/mapbox/streets-v11');

const sidebar_state = reactive({
  active_menu: 'layers',
  is_table_active: false,
});

// Computed Properties
const disabled_map_tools = computed(() => {
  const rectangle_and_circle_disabled = terra_store.settings.enable_snap
    ? ['add-rectangle', 'add-circle']
    : [];

  return [
    ...rectangle_and_circle_disabled,
  ];
});

function sourcesLayers() {
  const sources = ['all_features_source', 'symbol-source'];

  const layers
   = [
     {
       id: 'point_feature_layer',
       type: 'circle',
       source: 'all_features_source',
       filter: ['==', '$type', 'Point'],
     },
     {
       id: 'polygon_feature_layer',
       type: 'fill',
       source: 'all_features_source',
       paint: {
         'fill-opacity': 0,
       },
     },
     {
       id: 'linestring_feature_layer',
       type: 'line',
       source: 'all_features_source',
       paint: {
         'line-width': 2,
       },
     },
     {
       id: 'image-pattern-layer',
       type: 'fill',
       source: 'all_features_source',
       paint: {
         'fill-pattern': 'pattern-0',
       },
     },
     {
       id: 'symbol-layer-icon',
       type: 'symbol',

       source: 'symbol-source',
       layout: {
         'icon-image': ['coalesce', ['get', 'icon'], 'marker'],
         'icon-size': ['coalesce', ['get', 'icon_size'], 1],
         'icon-allow-overlap': true,
       },
     },
   ];
  return { sources, layers };
}

function formatFeatures(features) {
  return features.map(feature => ({
    geometry: terra_store.features_hash[feature?.properties?.uid]?.geometry || feature.geometry || feature._geometry,
    id: feature.id,
    properties: feature.properties,
    type: feature.type,
  }));
}

const { styleSelection, loadSelectionEvents } = useMapboxFeatureSelection({
  layer_ids: ['polygon_feature_layer', 'linestring_feature_layer', 'point_feature_layer'],
}, (e, event_type) => {
  if (event_type === 'ctrlSelect') {
    terra_store.clear_gl_draw();
    const filtered_features = terra_store.selected_features.filter(val => val?.properties?.uid !== e[0]?.properties?.uid);
    if (filtered_features.length === terra_store.selected_features.length)
      filtered_features.push(...formatFeatures(e));
    terra_store.selected_features = filtered_features;
    terra_store.terra_track_events('Feature clicked');
  }
  else if (event_type === 'ctrlShiftSelect') {
    const all_selected_features = [...terra_store.selected_features, ...formatFeatures(e)];
    terra_store.selected_features = uniqBy(all_selected_features, f => f.properties?.uid);
  }
  else {
    if (e.length === 0 && terra_store.draw?.getSelected()?.features?.length !== 0)
      return;

    terra_store.selected_features = uniqBy(e, f => f.properties?.uid).map((item) => {
      if (event_type === 'create' && item.geometry.type === 'Polygon') {
        let key = 'is_polygon';
        if (active_tool.value === 'add-rectangle')
          key = 'is_rectangle';
        else if (active_tool.value === 'add-circle')
          key = 'is_circle';
        item.properties.dataProperties = { [key]: true };
      }
      return {
        geometry: (!['create', 'update', 'selectionchange'].includes(event_type) ? terra_store.features_hash[item.properties?.uid]?.geometry : null) || item.geometry || item._geometry,
        id: item.id,
        properties: item.properties,
        type: item.type,
      };
    });
  }
  if (event_type === 'create' || (event_type === 'update' && terra_store.selected_features.length === 1)) {
    active_tool.value = '';
    terra_store.features_updated = [...terra_store.features_updated, ...terra_store.selected_features.map(item => item.id)];
  }
  if (event_type === 'clicked')
    terra_store.terra_track_events('Feature clicked');
  handleSelectEvents();
});
function handleSelectEvents() {
  const count = terra_store.selected_features.length;
  if (count !== 0) {
    const uid = terra_store.selected_features[0]?.properties?.uid;
    // while creating feature
    if (!uid)
      return;
    const is_geometry_changed = isEqual(terra_store.selected_features.map(f => [f.id, f.geometry]), terra_store.draw?.getAll()?.features?.map(f => [f.id, f.geometry]));
    terra_store.terra_track_events(is_geometry_changed ? 'Features geometry changed' : 'Features selected', { count, uid });
  }
}
const { loadSelectionEvents: loadTaskFormSelectionEvents } = useMapboxFeatureSelection({
  layer_ids: ['symbol-layer-icon'],
  style_selection: false,
  use_normal_selection: true,
  task_form_selection: true,
}, (e, type) => {
  if (!['update', 'create', 'selectionchange'].includes(type)) {
    const tasksForms = uniqBy(e, f => f.properties.uid).map(item => ({
      geometry: item.geometry || item._geometry,
      id: item.id,
      properties: item.properties,
      type: item.type,
    }));
    if (tasksForms.length === 1 && type === 'click') {
      const task_form = tasksForms[0].properties;
      logger.log(tasksForms);
      if (task_form.feature_type === 'task') {
        router.push({
          ...route,
          query: {
            task: btoa(JSON.stringify({
              id: task_form.task_form_uid,
              store_key: 'terra_task_store',
            })),
          },
        });
      }

      else {
        router.push({
          ...route,
          query: {
            form: btoa(JSON.stringify({
              form_uid: task_form.task_form_uid,
              store_key: 'terra_form_store',
            })),
          },
        });
      }

      return;
    }
    terra_store.selected_tasks_forms = keyBy(tasksForms, feature => feature.properties.task_form_uid);

    const symbolSourceItems = terra_store.map.getSource('symbol-source')._data;
    symbolSourceItems.features = symbolSourceItems.features.map((f) => {
      f.properties.icon = f.properties.icon.replace('-selected', '');
      if (terra_store.selected_tasks_forms[f.properties.task_form_uid])
        f.properties.icon += '-selected';

      return f;
    });

    terra_store.map.getSource('symbol-source').setData(symbolSourceItems);
  }
});
const mb_tiles_popup = useModal({
  component: MbTilesPopup,
  attrs: {
    onClose() {
      mb_tiles_popup.close();
    },
  },
});
watch(() => terra_store.polygon, (val) => {
  if (val) {
    getTasksAndForms();
  }
  else {
    task_store.tasks_map = {};
    forms_store.forms_map = {};
    addSymbols();
  }
});
watch(() => route.params.asset_id, (val) => {
  if (val)
    router.push({ name: 'maps-list', params: { type: 'maps-list', asset_id: val } });
});
watch(() => terra_store.selected_features, (val) => {
  styleSelection(val, {
    mapbox_instance: terra_store.map,
    colors_map_accessor: 'featureTypeId',
    colors_map: terra_store.feature_types,
  });
  if (val.length === 0 && !terra_store.is_creating_vector)
    terra_store.clear_gl_draw();
});
// Have one state flag and watch over that to update the symbols
watch(() => [task_store?.tasks(), forms_store.forms, terra_store.gallery_view_state.is_active, terra_store.update_symbols_on_the_map_flag], () => {
  addSymbols();
});
watch(() => terra_store.show_request_mbtiles_popup?.requested_reports, () => {
  if (terra_store.show_request_mbtiles_popup?.resolved_all_requests && Object.keys(terra_store.show_request_mbtiles_popup?.requested_reports || {}).length)
    mb_tiles_popup.open();
}, {
  deep: true,
});
watch(() => terra_store.filters_state.quick_filter, (val) => {
  if (val)
    sidebar_state.active_menu = 'filters';
});
const { initMapbox, loadMapBoxPackage, addMapboxToken, initDraw, setSources, setLayers, removeMapboxInstance } = useMap({}, async (event_data, event_name) => {
  if (event_name === 'loaded') {
    loadSelectionEvents({ mapbox_instance: terra_store.map });
    loadTaskFormSelectionEvents({ mapbox_instance: terra_store.map });
    loadTaskFormIcons({ map: terra_store.map });
    getData();

    const { sources, layers } = sourcesLayers();

    setSources(sources, terra_store.map);
    setLayers(layers, terra_store.map);
    terra_store.draw = await initDraw(terra_store.map);
    dragLayers({
      layer: 'symbol-layer-icon',
      source: 'symbol-source',
      save_location: false,
      layer_type: 'task/form',
      map_instance: terra_store.map,
    });
    terra_store.map.on('click', () => {
      if (!['add', 'scale', 'volume', 'elevation', 'create', 'add-polygon', 'add-rectangle', 'add-circle'].includes(active_tool.value))
        active_tool.value = '';
    });
  }
});
const { addIconMarkerForItemCreation, removeItemCreationIcon, iconMarkerForItemCreationSource } = useMapItemCreation({}, (e) => {
  task_form_centroid.value = e.lngLat;
  marker_location.value = { ...e };
});

async function getData(view_config = null) {
  try {
    terra_store.is_loading = true;
    await terra_store.set_ftg_and_update_features_styles({
      type: 'terra',
      uid: route.params.id,
    });

    await terra_store.set_container({
      uid: route.params.id,
      type: 'terra',
    });
    terra_store.terra_track_events('Viewed');
    terra_store.set_terra_workflows({
      asset_id: route.params.asset_id,
    });

    if (auth_store.check_split('merge_sm_data_with_terra_features'))
      await terra_store.set_sm_instances();

    const first_project = Object.values(
      Object.values(terra_store.container.groups)[0]?.projects || {},
    )[0];

    const custom_view_data = custom_view_store.views_map[route.query?.view_uid];
    const config = view_config || custom_view_data?.data;

    const view_present = await checkAndUpdateView(config);

    if (route.query?.metadata) {
      await flyToAssociatedFeature('terra');
    }
    else if (!view_present) {
      await terra_store.toggle_project({ project: first_project });
      terra_store.update_features_on_map_flag += 1;
    }

    toggleLabels();
    terra_store.load_patterns();
    await terra_store.update_map_features_and_polygon();
    if (!view_present) {
      sidebar_state.active_menu = 'layers';
      sidebar_state.is_table_active = false;
    }
    terra_store.is_loading = false;
  }
  catch (err) {
    logger.log(err);
    if (route.query?.metadata) {
      $toast({
        title: $t('Location not found'),
        text: $t('Can not navigate to the location. You don\'t have access, or the location is no longer available'),
        type: 'warning',
      });
    }

    terra_store.is_loading = false;
  }
}
async function getTasksAndForms(options = { tasks: true, forms: true }) {
  try {
    terra_store.is_loading = true;
    if (options.tasks) {
      await task_store.set_tasks({
        stage: 'TERRA',
        archive: false,
        page_size: Number.MAX_SAFE_INTEGER,
        page_number: 1,
        is_template: false,
        polygon: terra_store.polygon,
        asset_uid: route.params.asset_id,
      });
    }
    if (options.forms) {
      await forms_store.set_forms({
        query: {
          stage: 'TERRA',
          status: 'published',
          polygon: terra_store.polygon,
          is_child: true,
          all_access: true,
          asset_uid: route.params.asset_id,

        },
      });
    }
    addSymbols();
    terra_store.is_loading = false;
  }
  catch (err) {
    logger.log(err);
    terra_store.is_loading = false;
  }
}

async function checkAndUpdateView(config) {
  if (!config)
    return false;
  if (config?.active_groups?.length) {
    const container_copy = terra_store.container || {};
    const toggleGroup = async (uid) => {
      terra_store.active_group_map[uid].ortho_enabled = true;
      terra_store.active_group_map[uid].features_enabled = true;

      terra_store.projects_request_status.show = true;
      // Update group data for features and
      const group = await terra_store.set_group({ group: terra_store.container.groups[uid], container: terra_store.container });

      terra_store.projects_request_status = {
        total: 0,
        current: 0,
        cancel: false,
        show: false,
      };
      // Replace the group in container with updated group
      container_copy.groups[uid] = group;
    };
    await Promise.all(config.active_groups.map(group_uid => toggleGroup(group_uid)));
    terra_store.set_container({ container: container_copy });
  }
  if (config?.filtered_active_projects?.length) {
    const projects = [];
    config.filtered_active_projects.forEach((project_uid) => {
      const project = terra_store.active_projects_data_map({ all_projects: true })[project_uid];
      terra_store.active_projects_map[project.uid] = {
        ortho_enabled: true,
        features_enabled: true,
      };
      projects.push(project);
    });
    await terra_store.set_projects_essentials({ projects });
  }
  // trigger watcher in terra filter for current features
  terra_store.update_features_on_map_flag += 1;

  if (config.center) {
    terra_store.map.flyTo({
      center: [config.center.lng, config.center.lat],
      zoom: config.zoom_level || 18,
      speed: 4,
    });
  }
  if (config.inactive_feature_types)
    terra_store.inactive_feature_types = config.inactive_feature_types;
  if (config.tasks_cluster)
    terra_store.tasks_cluster = config.tasks_cluster;
  if (config.forms_cluster)
    terra_store.forms_cluster = config.forms_cluster;
  if (config.feature_config)
    terra_store.settings = { ...terra_store.settings, ...config.feature_config };
  if (config.table_state)
    terra_store.table_state = config.table_state;
  if (config.filters_state) {
    terra_store.filters_state = config.filters_state;
    terra_store.filters_state.features_on_map = terra_store.features;
    if (terra_store.filters_state.is_mounted) {
      sidebar_state.active_menu = 'filters';
      await terra_store.map.once('idle');
    }
  }
  if (config.sidebar_menu) {
    sidebar_state.active_menu = config.sidebar_menu.active_menu;
    sidebar_state.is_table_active = config.sidebar_menu.is_table_active;
    terra_store.update_features_on_map_flag += 1; // trigger watcher in terra filter
  }
  else {
    sidebar_state.active_menu = 'layers';
  }
  return true;
}

function switchDrawMode(mode) {
  if (terra_store.draw.getMode() === mode) {
    terra_store.draw.trash();
    active_tool.value = '';
  }
  else {
    terra_store.draw.changeMode(mode);
  }
}

function addEvents() {
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      terra_store.draw.changeMode('simple_select');
      document.removeEventListener('keydown');
    }
  });
}

function onYieldEstimatorClick() {
  is_yield_estimator_active.value = !is_yield_estimator_active.value;
  if (is_yield_estimator_active.value) {
    const center = terra_store.map.getCenter();
    logger.log('🚀 ~ file: terra-viewer.vue:390 ~ onYieldEstimatorClick ~ center:', center);
    if (!yield_estimator_marker.value) {
      const element = document.createElement('div');
      element.className = 'custom-yield-marker';

      element.style.backgroundImage = 'url(/img/icons/location-pin.png)';

      element.style.width = `${35}px`;
      element.style.height = `${35}px`;
      element.style.backgroundSize = '100%';
      logger.log('🚀 ~ file: terra-viewer.vue:321 ~ onYieldEstimatorClick ~ element:', element);
      yield_estimator_marker.value = new mapboxgl.Marker({
        element,
        draggable: true,
      })
        .setLngLat([center.lng, center.lat])
        .addTo(terra_store.map);
      yield_estimator_coordinates.value = [center.lng, center.lat];
    }

    const onDragEnd = () => {
      const lngLat = yield_estimator_marker.value.getLngLat();
      yield_estimator_coordinates.value = [lngLat.lng, lngLat.lat];
    };

    yield_estimator_marker.value.on('dragend', onDragEnd);
  }
  else {
    yield_estimator_marker.value.remove();
    yield_estimator_marker.value = null;
    active_tool.value = '';
  }
}

async function onToolActivated(tool_event_name) {
  if (active_tool.value === 'yield_estimator' && tool_event_name !== 'yield_estimator')
    onYieldEstimatorClick();
  if (
    ![
      'zoom-in',
      'zoom-out',
      'reset-north',
      'measure',
      'guide',
      'scale',
      'visualizer',
      'volume',
      'elevation',
      'filters',
      'settings',
      'yield_estimator',
      'create',
    ].includes(tool_event_name)
  ) {
    active_tool.value = tool_event_name;
  }
  if (['add-point', 'add-polygon', 'add-rectangle', 'add-line', 'add-circle'].includes(tool_event_name))
    terra_store.is_creating_vector = true;

  switch (tool_event_name) {
    case 'add-point':
      switchDrawMode('draw_point');

      addEvents();
      break;
    case 'add-polygon':
      switchDrawMode('draw_polygon');
      addEvents();
      break;
    case 'add-rectangle':
      switchDrawMode('draw_rectangle');
      addEvents();
      break;
    case 'add-line':
      switchDrawMode('draw_line_string');
      addEvents();
      break;
    case 'add-circle':
      switchDrawMode('drag_circle');
      addEvents();
      break;
    case 'zoom-in':
      terra_store.map.zoomIn();
      break;
    case 'zoom-out':
      terra_store.map.zoomOut();
      break;
    case 'reset-north':
      await terra_store.fly_to_project({
        project: terra_store.last_selected_project,
      });
      break;
    case 'scale':
    case 'volume':
    case 'yield_estimator':
    case 'elevation':
    case 'settings':
    case 'filters':
    case 'create':
      active_tool.value = active_tool.value === tool_event_name ? '' : tool_event_name;
      break;
  }
}

function disableCreationMarker() {
  open_task_popup.value = false;
  open_form_popup.value = false;
  task_marker_active.value = false;
  form_marker_active.value = false;
  removeItemCreationIcon(terra_store.map);
  marker_location.value = null;
  project_details_for_task_form_creation.value = null;
}
function toggleCreation(type) {
  active_tool.value = '';

  if (type === 'tasks') {
    if (terra_store.selected_features.length) {
      open_task_popup.value = true;
      return;
    }
    task_marker_active.value = !task_marker_active.value;
    if (!task_marker_active.value) {
      disableCreationMarker();
      return;
    }
  }
  else if (type === 'forms') {
    if (terra_store.selected_features.length) {
      open_form_popup.value = true;
      return;
    }
    form_marker_active.value = !form_marker_active.value;
    if (!form_marker_active.value) {
      disableCreationMarker();
      return;
    }
  }

  addIconMarkerForItemCreation({
    map_instance: terra_store.map,
    icon: 'location-marker',
  });
  if (task_marker_active.value || form_marker_active.value)
    iconMarkerForItemCreationSource({ lngLat: terra_store.map.getCenter() }, terra_store.map);
}

/* -------------------------------------------------------------------------- */
/*                                   Labels                                   */
/* -------------------------------------------------------------------------- */

function toggleLabels() {
  // TODO Move to store and call on feature update/create
  if (terra_store.map) {
    // TODO add this to the top in the order of layers and remove this

    if (terra_store.map.getLayer('features_labels'))
      terra_store.map.removeLayer('features_labels');
    if (terra_store.map.getSource('features_labels'))
      terra_store.map.removeSource('features_labels');
  }
  terra_store.map.loadImage('/img/icons/mapbox-text-bg.png', (error, image) => {
    if (error)
      throw error;
    if (!terra_store.map.hasImage('mapbox-text-bg'))
      terra_store.map.addImage('mapbox-text-bg', image, {});

    if (
      (terra_store.settings.display_labels)
      && terra_store.map

    ) {
      const source = terra_store.map.getSource('features_labels');
      const featureCollection = {
        type: 'FeatureCollection',
        features: terra_store.features_on_map,
      };
      const pointCollection = terra_store.map.getSource('marker-source');
      if (route.name === 'map-view' && pointCollection) {
        featureCollection.features = [
          ...featureCollection.features,
          ...pointCollection._data.features,
        ];
      }
      const property_key = terra_store.settings.label_property_key;
      featureCollection.features = featureCollection.features.reduce((acc, f) => {
        let sm_properties = {};
        if (f.properties.name)
          sm_properties = terra_store.sm_instances_map.feature_name_hash[f.properties.name];
        const additional_properties = terra_store.get_feature_additional_properties(f);
        const properties = { ...additional_properties, ...(f.properties.extraProperties || {}), ...sm_properties, name: f.properties.name };
        if (!properties?.[property_key])
          return acc;
        f.properties[property_key] = properties[property_key];
        acc.push(f);
        return acc;
      }, []);

      if (source) {
        source.setData(featureCollection);
      }

      else {
        terra_store.map.addSource('features_labels', {
          type: 'geojson',
          data: featureCollection,
        });
      }

      const layer = terra_store.map.getLayer('features_labels');
      if (!layer) {
        terra_store.map.addLayer({
          id: 'features_labels',
          type: 'symbol',

          source: 'features_labels',
          paint: {
            'text-color': '#202',
          },
          layout: {
            'icon-size': 1.2,
            // "text-allow-overlap": true,
            // "icon-allow-overlap": true,
            'icon-image': 'mapbox-text-bg',
            'icon-text-fit': 'both',
            'text-justify': 'center',
            'text-anchor': 'center',
            'text-field': ['get', `${property_key}`],
            'text-font': ['literal', ['Arial Unicode MS Regular']],
            'text-size': 10,
          },
        });
      }
    }
  });
}

async function handleMapStyleChange() {
  try {
    if (map_style.value.includes('satellite')) {
      terra_store.map.setStyle('mapbox://styles/mapbox/streets-v11');
      map_style.value = 'mapbox://styles/mapbox/streets-v11';
    }
    else {
      terra_store.map.setStyle('mapbox://styles/mapbox/satellite-v8');
      map_style.value = 'mapbox://styles/mapbox/satellite-v8';
    }

    localStorage.setItem('map_style', map_style.value);

    setTimeout(async () => {
      terra_store.is_loading = true;
      loadTaskFormSelectionEvents({ mapbox_instance: terra_store.map });
      loadTaskFormIcons({ map: terra_store.map });
      const { sources, layers } = sourcesLayers();

      sources.forEach((key) => {
        if (terra_store.map.getSource(key))
          terra_store.map.removeSource(key);
      });
      layers.forEach((key) => {
        if (terra_store.map.getLayer(key))
          terra_store.map.removeLayer(key);
      });
      setSources(sources, terra_store.map);
      setLayers(layers, terra_store.map);
      if (!terra_store.active_projects.length) {
        const first_project = Object.values(
          Object.values(terra_store.container.groups)[0]?.projects || {},
        )[0];
        terra_store.active_projects_map[first_project.uid].ortho_enabled = terra_store.active_projects_map[first_project.uid].features_enabled = false;

        await terra_store.toggle_project({ project: first_project });
      }
      else {
        await Promise.all(terra_store.active_projects.map(async ({ group_uid, uid }) => {
          terra_store.active_projects_map[uid].ortho_enabled = terra_store.active_projects_map[uid].features_enabled = false;
          const project = terra_store.container.groups?.[group_uid]?.projects?.[uid] || { group_uid, uid };
          return await terra_store.toggle_project({ project });
        }));
      }

      terra_store.load_patterns();
      terra_store.update_features_styles();
      terra_store.update_map_features_and_polygon();
      loadSelectionEvents({
        mapbox_instance: terra_store.map,

      });
      await getTasksAndForms();
    }, 1500);
  }
  catch (err) {
    terra_store.is_loading = false;
    logger.log(err);
  }
}

function confirmLocation(data) {
  project_details_for_task_form_creation.value = data;
  if (task_marker_active.value)
    open_task_popup.value = true;
  else if (form_marker_active.value)
    open_form_popup.value = true;
}

async function onContainerChange(e) {
  const url = router.resolve({
    name: 'terra-viewer',
    params: {
      asset_id: route.params.asset_id,
      id: e.uid,
    },
  }).href;
  window.location.href = url;
  // try {
  //   terra_store.$reset();
  //   terra_store.is_loading = true;
  //   terra_store.container = e;
  //   terra_store.map = await initMapbox({
  //     container_id: 'terra-viewer',
  //     style: map_style.value,
  //   });

  //   terra_store.is_loading = false;
  // }
  // catch (err) {
  //   terra_store.is_loading = false;
  // }
}

onMounted(async () => {
  try {
    terra_store.is_loading = true;
    await loadMapBoxPackage();

    await addMapboxToken();
    terra_store.map = await initMapbox({
      container_id: 'terra-viewer',
      style: map_style.value,
    });

    terra_store.map.boxZoom.disable();
    // To change icons to draft state once saved
    emitter.on('form_save', () => {
      addSymbols();
    });
  }
  catch (err) {
    logger.error(err);
    terra_store.is_loading = false;
  }
});
onUnmounted(() => {
  removeMapboxInstance(terra_store.map);
  terra_store.$reset();
});

const projects_request_status = computed(() => terra_store.projects_request_status);

const progress_text = computed(() => {
  return `Loading layers (${projects_request_status.value.current}/${projects_request_status.value.total})`;
});

async function exporting() {
  while (projects_request_status.value.show && !projects_request_status.value.cancel) {
    await new Promise((resolve) => {
      setTimeout(() => resolve('loading layers'), 2000);
    });
  }
}

function groupOrProjectToggled() {
  terra_store.show_save_view = true;
  if (terra_store.settings.display_labels)
    toggleLabels();
}

function filterGlDrawConvertedFeatures() {
  const feature_ids = terra_store.draw?.getAll()?.features.map(item => item.properties.uid);
  terra_store.map.setFilter('polygon_feature_layer', [
    'match',
    ['get', 'uid'],
    feature_ids,
    false,
    true,
  ]);
  terra_store.map.setFilter('linestring_feature_layer', [
    'match',
    ['get', 'uid'],
    feature_ids,
    false,
    true,
  ]);
  terra_store.map.setFilter('point_feature_layer', [
    ['==', '$type', 'Point'],
    'match',
    ['get', 'uid'],
    feature_ids,
    false,
    true,
  ]);
}

function handleUpdateTableView(e) {
  sidebar_state.is_table_active = e;
  if (!e) {
    terra_store.table_state = {
      search: '',
      filters: [],
      ordered_keys_map: {},
      sort: [],
      should_sort_selected_features: false,
    };
  }
}

async function changeView(view_config = null) {
  try {
    const map = terra_store.map;
    const draw = terra_store.draw;
    const projects = Object.entries(terra_store.active_projects_map).filter(([, project]) => project.ortho_enabled).map(([project_uid]) => terra_store.active_projects_data_map({ all_projects: true })[project_uid]);
    terra_store.$reset();
    terra_store.map = map;
    terra_store.draw = draw;
    sidebar_state.is_table_active = false;

    await getData(view_config);
    terra_store.set_projects_ortho({ projects });
  }
  catch (error) {
    logger.log('Error Occurred', error);
    terra_store.is_loading = false;
  }
}
</script>

<template>
  <div class="relative -top-0.5">
    <div v-if="projects_request_status.show && !projects_request_status.cancel">
      <HawkExportToast :key="projects_request_status.current" class="z-[102]" :submit="exporting" :progress_text="progress_text" completed_text="Loaded blocks successfully" @cancel="terra_store.projects_request_status.cancel = true" />
    </div>
    <div v-if="terra_store.is_loading && !projects_request_status.show" class="absolute w-full h-[calc(100vh-65px)] z-[1001] bg-gray-100 opacity-[0.9] flex justify-center items-center">
      <HawkLoader />
    </div>

    <TerraSidebarMenu
      :sidebar_state="sidebar_state"
      @update-table-active="handleUpdateTableView($event)"
      @update-active-menu="sidebar_state.active_menu = $event"
      @open-creation-popup="($event) => {
        if ($event === 'task')
          open_task_popup = true
        else open_form_popup = true
      }"
      @container-change="onContainerChange"
      @set-symbols="addSymbols()"
      @toggle-labels="toggleLabels"
      @group-or-project-toggled="groupOrProjectToggled"
      @save-view="terra_store.show_save_view = true"
    />
    <div>
      <FeatureDetails
        v-if="terra_store.selected_features && terra_store.selected_features.length >= 1 && (!task_marker_active && !form_marker_active)"
        @filter-gl-draw-converted-features="filterGlDrawConvertedFeatures"
        @is-table-active="sidebar_state.is_table_active = true; terra_store.table_state.should_sort_selected_features = true;"
        @open-creation-popup="($event) => {
          if ($event === 'task')
            open_task_popup = true
          else open_form_popup = true
        }"
      />

      <div
        class="ml-5 !h-auto fixed top-16 z-[99] transition-all ease-in-out duration-300"
        :style="
          `${
            Boolean(sidebar_state.active_menu.length)
              ? `left:370px; width:calc(100vw - ${terra_store.gallery_view_state.is_active ? '800px' : '400px'});`
              : `left:70px; width:calc(100vw - ${terra_store.gallery_view_state.is_active ? '500px' : '100px'});`
          }`
        "
      >
        <MapTools
          type="terra"
          :active_tools="{
            guide: guide_enable,
            scale: scale_enable,
            create: create_enable,
            visualizer: visualizer_enable,
            create: task_marker_active || form_marker_active,
            settings: settings_active,
            filters: filters_active,
            is_yield_estimator_active,
          }"
          :sidebar_state="sidebar_state"
          :draw_tools_expanded="false"
          :active_tool="active_tool"
          :disabled="disabled_map_tools"
          @tool-activated="onToolActivated"
          @toggle-create="(task_marker_active || form_marker_active) ? disableCreationMarker() : null"
          @toggle-yield-estimator="onYieldEstimatorClick"
          @compare="$router.push({ name: 'terra-compare', params: { ...route.params } })"
          @change-view="changeView"
          @close-save-view="terra_store.show_save_view = false"
          @filter-gl-draw-converted-features="filterGlDrawConvertedFeatures"
          @open-creation-popup="($event) => {
            if ($event === 'task')
              open_task_popup = true
            else open_form_popup = true
          }"
        />

        <CreateItems v-if="active_tool === 'create'" class="absolute right-[274px] mt-10" @create-task="toggleCreation('tasks')" @create-form="toggleCreation('forms')" />

        <ConfirmLocation v-if="marker_location && (task_marker_active || form_marker_active)" :key="marker_location" class="fixed bottom-8 right-8" :marker_location="marker_location" @close="disableCreationMarker" @confirm="confirmLocation" />
        <div class="absolute right-[45px] mt-10">
          <Volume v-if="active_tool === 'volume'" />
          <Elevation v-if="active_tool === 'elevation'" />
          <Scale v-if="active_tool === 'scale'" :selected_features="terra_store.selected_features" />
        </div>
        <TerraTaskForm v-if="open_task_popup" :marker_location="task_form_centroid" opened_from="Direct" :project_details_for_task_form_creation="project_details_for_task_form_creation" class="absolute right-[370px] mt-10" @close="disableCreationMarker" />
        <TerraNewForm v-if="open_form_popup" :marker_location="task_form_centroid" opened_from="Direct" :project_details_for_task_form_creation="project_details_for_task_form_creation" class="absolute right-[370px] mt-10" @close="disableCreationMarker" />
        <YieldEstimator
          v-if="active_tool === 'yield_estimator'"
          class="fixed bottom-3 right-3"
          :coordinates="yield_estimator_coordinates"
          @close="onYieldEstimatorClick()"
        />
        <div
          v-if="map_style"
          v-tippy="!map_style.includes('satellite') ? $t('Satellite') : $t('Street')"
          class="fixed bottom-2"
          @click="handleMapStyleChange"
        >
          <img
            v-if="!map_style.includes('satellite')"
            src="../assets/satellite.jpg" alt="Satellite" class="cursor-pointer !rounded-lg border-2 border-white"
          >
          <img
            v-else
            src="../assets/street.jpg" alt="Street" class="cursor-pointer !rounded-lg border-2 border-white"
          >
        </div>
      </div>

      <RestoreView
        :restore_view_config="terra_store.restore_view_config"
        @handle-restore="changeView($event)"
      />

      <div id="terra-viewer" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#terra-viewer {
    position: fixed;
    width: 100%;
    top: 65px;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
}
:deep(.boxdraw)  {
    background: rgba(56, 135, 190, 0.1);
    border: 2px solid #3887be;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }
</style>
