<script setup>
import { onMounted } from 'vue';
import HawkActivitiesTemplate from '~/common/components/organisms/hawk-activities/hawk-activities-template.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  activity: {
    type: Object,
    default: () => {},
  },
  // eslint-disable-next-line vue/prop-name-casing
  show_form_name: {
    type: Boolean,
    default: true,
  },
});

const { $t } = useCommonImports();

const priority_map = {
  critical: 1,
  high: 2,
  medium: 3,
  low: 4,
  not_set: 5,
};

const show_to = ref(true);
const show_from = ref(true);

const FORM_ACTIVITY_VERB_MAP = {
  CREATED: $t('created the form'),
  RENAMED: $t('renamed the form'),
  DESCRIPTION_CHANGED: $t('changed description'),
  CATEGORY_CHANGED: () => {
    const form_uid = props.activity.meta.category.from.uid;
    const to_uid = props.activity.meta.category.to.uid;
    if (!form_uid && to_uid)
      return $t('set the category');
    else if (form_uid && !to_uid)
      return $t('removed the category');
    else return $t('changed category');
  },
  ASSIGNEES_ADDED: $t('added assignees to the form'),
  ASSIGNEES_REMOVED: $t('removed assignees from the form'),
  MEMBERS_ADDED: $t('added members to the form'),
  MEMBERS_REMOVED: $t('removed members from the form'),
  TAGS_ADDED: $t('added the tags'),
  TAGS_REMOVED: $t('removed the tags'),
  TAGS_DELETED: $t('deleted the tags'),
  LOCATION_CHANGED: () => {
    if (!props.activity?.meta?.location?.from?.reference_name)
      return $t('set the location');
    else if (!props.activity?.meta?.location?.to?.reference_name)
      return $t('removed the location');
    else return $t('changed the location');
  },
  STATUS_CHANGED: $t('status changed'),
  START_DATE_CHANGED: () => {
    const form_date = props.activity.meta?.start_date?.from;
    const to_date = props.activity.meta?.start_date?.to;
    if (!form_date && to_date)
      return $t('set the start date');
    else if (form_date && !to_date)
      return $t('removed the start date');
    else return $t('changed start date');
  },
  DUE_DATE_CHANGED: () => {
    const form_date = props.activity.meta?.due_date?.from;
    const to_date = props.activity.meta?.due_date?.to;
    if (!form_date && to_date)
      return $t('set the due date');
    else if (form_date && !to_date)
      return $t('removed the due date');
    else return $t('changed due date');
  },
  PRIORITY_CHANGED: $t('set the priority'),
  SAVED: $t('saved the details'),
  SUBMITTED: $t('submitted'),
  ROLLED_BACK: $t('rolled back'),
  REOPENED: $t('reopened'),
  SCHEDULE_CHANGED: () => {
    if (!props.activity.meta?.schedule?.from?.rrule)
      return $t('set the schedule');
    else return $t('modified the schedule');
  },
  SCHEDULE_TRIGGERED: $t('form schedule triggered'),
  ESCALATED: $t('Form escalated'),
  DELETED: $t('deleted the form'),
  BLOCK_EXECUTED: $t('block executed'),
  NOTIFICATION_SENT: $t('notification sent'),
  HOOK_TRIGGERED: $t('hook triggered'),
  APPROVAL_STARTED: $t('request to view'),
  APPROVAL_REVIEWED: '',
  APPROVAL_ESCALATED: '',
  APPROVAL_COMPLETED: '',
  REPORT_EXPORTED: $t('generated/ exported report'),
  OWNER_CHANGED: $t('changed owner'),
};

const TEMPLATE_ACTIVITY_VERB_MAP = {
  CREATED: $t('created the template'),
  RENAMED: $t('renamed the template'),
  DESCRIPTION_CHANGED: $t('updated description'),
  CATEGORY_CHANGED: () => {
    const form_uid = props.activity.meta.category.from.uid;
    const to_uid = props.activity.meta.category.to.uid;
    if (!form_uid && to_uid)
      return $t('set the category');
    else if (form_uid && !to_uid)
      return $t('removed the category');
    else return $t('changed category');
  },
  DUE_DATE_CHANGED: () => {
    const form_date = props.activity.meta?.due_date?.from?.value;
    const to_date = props.activity.meta?.due_date?.to?.value;
    if (!form_date && to_date)
      return $t('set the due date');
    else if (form_date && !to_date)
      return $t('removed the due date');
    else return $t('changed due date');
  },

  START_DATE_CHANGED: () => {
    const form_date = props.activity.meta?.start_date?.from;
    const to_date = props.activity.meta?.start_date?.to;
    if (!form_date && to_date)
      return $t('set the start date');
    else if (form_date && !to_date)
      return $t('removed the start date');
    else return $t('changed start date');
  },
  PUBLISHED: $t('published the form'),
  UNPUBLISHED: $t('unpublished the form'),
  MEMBERS_ADDED: $t('added members to the template'),
  MEMBERS_REMOVED: $t('removed members from the template'),
  DELETED: $t('deleted the template'),
  FIELD_ADDED: $t('added the field'),
  SECTION_ADDED: $t('added the section'),
  FIELD_UPDATED: $t('updated the field'),
  SECTION_UPDATED: $t('updated the section'),
  FIELD_DELETED: $t('deleted the field'),
  SECTION_DELETED: $t('deleted the section'),
  BLOCK_ADDED: $t('added the block'),
  BLOCK_UPDATED: $t('updated the block'),
  BLOCK_DELETED: $t('deleted the block'),
  SETTINGS_UPDATED: $t('modified the settings'),

};

const is_form = computed(() => {
  return props.activity.resource_name === 'FORM';
});

onMounted(() => {
  if (props.activity.verb === 'NOTIFICATION_SENT')
    show_to.value = false;
  if (['SCHEDULE_CHANGED', 'DESCRIPTION_CHANGED'].includes(props.activity.verb)) {
    show_from.value = false;
    show_to.value = false;
  }
});

function getFormActivityMessage(verb, from, to) {
  if (typeof FORM_ACTIVITY_VERB_MAP[verb] === 'function') {
    const message = FORM_ACTIVITY_VERB_MAP[verb](from, to);
    if ([$t('set the category'), $t('set the due date'), $t('set the start date'), $t('set the location')].includes(message))
      show_from.value = false;
    if ([$t('removed the category'), $t('removed the due date'), $t('removed the start date'), $t('removed the location')].includes(message)) {
      show_from.value = false;
      show_to.value = false;
    }
    if (['SCHEDULE_CHANGED', 'DESCRIPTION_CHANGED'].includes(verb)) {
      show_from.value = false;
      show_to.value = false;
    }
    return message;
  }

  else {
    return FORM_ACTIVITY_VERB_MAP[verb];
  }
}

function getTemplateActivityMessage(verb, from, to) {
  if (typeof TEMPLATE_ACTIVITY_VERB_MAP[verb] === 'function') {
    const message = TEMPLATE_ACTIVITY_VERB_MAP[verb](from, to);
    if ([$t('set the category'), $t('set the due date'), $t('set the start date'), $t('set the location')].includes(message))
      show_from.value = false;
    if ([$t('removed the category'), $t('removed the due date'), $t('removed the start date'), $t('removed the location')].includes(message)) {
      show_from.value = false;
      show_to.value = false;
    }
    if (['SCHEDULE_CHANGED', 'DESCRIPTION_CHANGED'].includes(verb)) {
      show_from.value = false;
      show_to.value = false;
    }

    return message;
  }

  else {
    return TEMPLATE_ACTIVITY_VERB_MAP[verb];
  }
}

function getActivityMessage(verb, from, to) {
  if (is_form.value)
    return getFormActivityMessage(verb, from, to);

  else
    return getTemplateActivityMessage(verb, from, to);
}
</script>

<template>
  <div>
    <div v-if="show_form_name" class="flex justify-between text-sm text-grey-600 mb-1">
      <div v-if="props.activity.resource_name === 'FORM_TEMPLATE'" class="flex items-center">
        {{ props.activity.meta?.template?.name }}
        <IconHawkChevronRight v-if="props.activity.meta?.form?.name" aria-hidden="true" class="w-[16px] h-[16px] text-gray-300" />
        {{ props.activity.meta?.form?.name }}
      </div>
      <div v-else>
        {{ props.activity.meta?.form?.name }}
      </div>
      <div>
        <slot name="time" />
      </div>
    </div>
    <div class="flex flex-wrap">
      <slot name="activity_prefix" />
      <div v-if="activity.verb === 'NOTIFICATION_SENT'" class="text-sm">
        {{ activity.meta.notification.type }} {{ activity.meta.notification.channel }} notification sent to <HawkMembers :members="activity.meta.notification.to" type="badge" />
      </div>

      <HawkActivitiesTemplate v-else :activity="props.activity" class="text-sm" :exclude_keys="['form', 'template']">
        <template #action="{ action }">
          <span>
            {{ getActivityMessage(action.verb, action.from, action.to) }}
          </span>
        </template>
        <template v-if="show_from" #from="{ from }">
          <span class="mx-[5px]">
            {{ $t('from') }}
          </span>
          <div v-if="from?.key === 'due_date' || from?.key === 'start_date'">
            {{ $date(from?.value) }}
          </div>
          <div v-if="props.activity.verb === 'OWNER_CHANGED'">
            <hawk-members :members="props.activity.meta.owner.from" type="badge" size="sm" class="mr-2" />
          </div>
          <div v-if="from?.key === 'status' || from.key === 'rolled_back'">
            <HawkBadge
              :rounded="true"
              :custom_color="from?.value?.color || '#667085'"
            >
              {{ from?.value?.label || from?.value?.name }}
            </HawkBadge>
          </div>
          <div v-if="from?.key === 'name' ">
            {{ from?.value }}
          </div>
          <div v-if="from?.key === 'location'">
            {{ props.activity?.meta?.location?.from?.reference_name }}
          </div>

          <TaskPriority v-if="from.key === 'priority'" :priority="priority_map[from.value]" />
          <HawkCategoryName v-if="from.key === 'category'" :uid="from?.value?.uid" class="inline-flex" />
        </template>
        <template v-if="show_to" #to="{ to }">
          <span class="mx-[5px]">
            {{ $t('to') }}
          </span>
          <div v-if="to?.key === 'due_date' || to.key === 'start_date'">
            {{ $date(to?.value) }}
          </div>
          <div v-if="props.activity.verb === 'OWNER_CHANGED'">
            <hawk-members :members="props.activity.meta.owner.to" type="badge" size="sm" class="mr-2" />
          </div>
          <div v-if="to?.key === 'status' || to.key === 'reopened' || to.key === 'rolled_back'">
            <HawkBadge
              :rounded="true"
              :custom_color="to?.value?.color || '#667085'"
            >
              {{ to?.value?.label || to?.value?.name }}
            </HawkBadge>
          </div>
          <div v-if="to?.key === 'name'">
            {{ to?.value }}
          </div>
          <div v-if="to?.key === 'location'">
            {{ props.activity?.meta?.location?.to?.reference_name }}
          </div>

          <TaskPriority v-if="to.key === 'priority'" :priority="priority_map[to.value]" />
          <HawkCategoryName v-else-if="to?.key === 'category'" :uid="to?.value?.uid" class="inline-flex" />
        </template>
        <template #meta="{ meta }">
          <div class="mx-[5px] text-gray-600">
            <div v-if="props.activity.verb === 'SUBMITTED'">
              {{ activity.meta.step?.name }}
            </div>
            <div v-else-if="props.activity.verb === 'APPROVAL_STARTED'">
              {{ meta.items.name }}
            </div>
            <div v-else-if="props.activity.verb === 'APPROVAL_COMPLETED'">
              {{ meta.items.name }} {{ $t('is completed with final outcome as') }} {{ props.activity.meta.outcome }}
            </div>
            <div v-else-if="props.activity.verb === 'APPROVAL_REVIEWED'">
              {{ props.activity.meta.review.status }} for {{ props.activity.meta.outcome }} {{ meta.items.name }}
            </div>
            <div v-else-if="props.activity.verb === 'APPROVAL_ESCALATED'">
              {{ meta.items.name }} is escalated to
              <hawk-members :members="props.activity.meta.escalation.members" type="badge" size="sm" class="mr-2" />
            </div>
            <div v-else-if="['section', 'block', 'field'].includes(meta.key)">
              {{ meta.items.name }}
            </div>
            <div v-else-if="meta.key === 'assignees'">
              <HawkMembers :members="meta.items.added || meta.items.removed" type="badge" />
            </div>
            <div v-else-if="meta.key === 'tags'">
              <HawkTagsName :tags="meta.items.added || meta.items.removed" />
            </div>
          </div>
        </template>
      </HawkActivitiesTemplate>
      <div v-if="['removed the category'].includes(getActivityMessage(props.activity.verb, true, false))" class="mx-[5px] text-sm">
        <HawkCategoryName :uid="props.activity?.meta?.category?.from?.uid" />
      </div>
      <div v-if="['removed the start date'].includes(getActivityMessage(props.activity.verb, true, false))" class="mx-[5px] text-sm">
        {{ $date(props.activity?.meta?.due_date?.from) }}
      </div>
      <div v-if="['removed the due date'].includes(getActivityMessage(props.activity.verb, true, false))" class="mx-[5px] text-sm">
        {{ $date(props.activity?.meta?.start_date?.from) }}
      </div>
      <div v-if="['removed the location'].includes(getActivityMessage(props.activity.verb, true, false))" class="mx-[5px] text-sm">
        {{ props.activity?.meta?.location?.from?.reference_name }}
      </div>
    </div>
  </div>
</template>
