<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { Validator } from '@vueform/vueform';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import { keyBy } from 'lodash-es';
import { RRule } from 'rrule';

import { inject } from 'vue';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  module: {
    type: String,
    required: true,
  },
  recur_when: {
    type: Object,
    required: true,
  },
  schedule: {
    type: Object,
    default: () => ({}),
  },
  start_date: {
    type: String,
  },
});
const $t = inject('$t');

dayjs.extend(minMax);
dayjs.extend(timezone);

const auth_store = useAuthStore();

const DEFAULT_NUM_VALUES = {
  count: 1,
  interval: 1,
  duration: 0,
  days_before: 0,
};

const form$ = ref(null);
const form_data = ref(null);

onMounted(() => {
  if (props.schedule?.rrule)
    loadData();
  else
    loadDefault();
});

const daysBeforeValidator = class extends Validator {
  get message() {
    return $t(`Invalid data. Results in simultaneous ${props.module}s`);
  }

  check(value) {
    const day_factor = {
      Daily: 1,
      Weekly: 7,
      Monthly: 30,
      Annually: 365,
    };
    return value <= day_factor[form_data.value.recurrence_frequency] * form_data.value.interval;
  }
};

function loadData() {
  const { options: existingRules } = RRule.fromString(props.schedule.rrule);
  const data = {};
  data.interval = existingRules.interval;
  // Repeatation block
  let repeat = 'Repeat forever';
  if (existingRules?.count)
    repeat = 'Repeat for';
  else if (existingRules?.until)
    repeat = 'Repeat until';
  data.repeatation = {
    count: existingRules?.count,
    date: existingRules?.until,
    repeat,
  };
  data.recur_when = props.schedule.recur_when;
  data.duration = props.schedule.duration || DEFAULT_NUM_VALUES.duration;
  data.days_before = props.schedule.days_before;
  switch (existingRules.freq) {
    case 0:
      data.recurrence_frequency = 'Annually';
      data.month_of_year = existingRules.bymonth;
      data.on_the = {
        week_day: existingRules.bynweekday[0][0],
        week_number: existingRules.bynweekday[0][1],
      };
      break;
    case 1:
      data.recurrence_frequency = 'Monthly';
      if (existingRules?.bymonthday?.length || existingRules?.bynmonthday?.length) {
        data.schedule_group = {
          freq: 'On particular dates',
          month_dates: [...(existingRules.bymonthday || []), ...(existingRules.bynmonthday || [])],
        };
      }
      else {
        data.schedule_group = {
          freq: 'On the',
          on_the: {
            week_day: existingRules.bynweekday[0][0],
            week_number: existingRules.bynweekday[0][1],
          },
        };
      }
      break;
    case 2:
      data.recurrence_frequency = 'Weekly';
      data.selected_weekdays = existingRules.byweekday;
      break;
    case 3:
      data.recurrence_frequency = 'Daily';
      break;
    default:
      data.recurrence_frequency = 'Monthly';
      data.schedule_group.on_the.month_dates = existingRules.bymonthday;
  }
  form_data.value = data;
}

function loadDefault(recurrence_frequency = 'Daily') {
  const data = {
    recurrence_frequency,
    interval: DEFAULT_NUM_VALUES.interval,
    recur_when: props.recur_when.default,
    repeatation: {
      repeat: 'Repeat forever',
      count: DEFAULT_NUM_VALUES.count,
      until: null,
    },
    duration: DEFAULT_NUM_VALUES.duration,
    days_before: DEFAULT_NUM_VALUES.days_before,
    // Annual
    month_of_year: [1],
    on_the: {
      week_number: 1,
      week_day: 0,
    },
    // Monthly
    schedule_group: {
      freq: 'On particular dates',
      month_dates: [1],
      on_the: {
        week_number: 1,
        week_day: 0,
      },
    },
    // Weekly
    selected_weekdays: [6],
  };
  form_data.value = data;
}

/* ---------Mapping and options----------- */
const frequency_map = {
  Daily: 'days',
  Weekly: 'weeks',
  Monthly: 'months',
  Annually: 'years',
};
const schedule_items = computed(() => {
  if (form_data.value?.recurrence_frequency === 'Monthly') {
    return [
      ...[...Array.from({ length: 31 }).keys()].map(i => i + 1),
      ...[-4, -3, -2, -1],
    ];
  }
  else if (form_data.value?.recurrence_frequency === 'Annually') {
    return [
      { label: $t('Jan'), value: 1 },
      { label: $t('Feb'), value: 2 },
      { label: $t('Mar'), value: 3 },
      { label: $t('Apr'), value: 4 },
      { label: $t('May'), value: 5 },
      { label: $t('Jun'), value: 6 },
      { label: $t('Jul'), value: 7 },
      { label: $t('Aug'), value: 8 },
      { label: $t('Sep'), value: 9 },
      { label: $t('Oct'), value: 10 },
      { label: $t('Nov'), value: 11 },
      { label: $t('Dec'), value: 12 },
    ];
  }
  else {
    return [];
  }
});
const weekday_items = [
  { label: $t('Sun'), name: $t('Sunday'), value: 6 },
  { label: $t('Mon'), name: $t('Monday'), value: 0 },
  { label: $t('Tue'), name: $t('Tuesday'), value: 1 },
  { label: $t('Wed'), name: $t('Wednesday'), value: 2 },
  { label: $t('Thu'), name: $t('Thursday'), value: 3 },
  { label: $t('Fri'), name: $t('Friday'), value: 4 },
  { label: $t('Sat'), name: $t('Saturday'), value: 5 },
];
/* ---------Mapping and options END----------- */

// Override classes for palette design
const override_class_map = {
  Monthly: {
    wrapper: '!grid grid-cols-7',
    container: 'col-span-1 month_border_class',
  },
  Annually: {
    wrapper: '!grid grid-cols-4',
    container: 'col-span-1 annual_border_class',
  },
};

/* ---------RRule helpers----------- */
function setWeekRule() {
  const rule = [];
  const value_name_map = keyBy(weekday_items, 'value');
  form_data.value.selected_weekdays.forEach((day_number) => {
    rule.push(RRule[value_name_map[day_number].label.slice(0, 2).toUpperCase()]);
  });
  return rule;
}
function setMonthRule() {
  const month_rule = {};
  if (form_data.value.schedule_group.freq === 'On particular dates') {
    month_rule.bymonthday = form_data.value.schedule_group.month_dates;
  }
  else {
    month_rule.byweekday = [];
    const value_name_map = keyBy(weekday_items, 'value');
    const key = value_name_map[form_data.value.schedule_group.on_the.week_day].label.slice(0, 2).toUpperCase();
    month_rule.byweekday = RRule[key].nth(form_data.value.schedule_group.on_the.week_number);
  }
  return month_rule;
}
function setYearRule() {
  const year_rule = {
    bymonth: form_data.value.month_of_year,
    byweekday: [],
  };
  const value_name_map = keyBy(weekday_items, 'value');
  const key = value_name_map[form_data.value.on_the.week_day].label.slice(0, 2).toUpperCase();
  year_rule.byweekday = RRule[key].nth(form_data.value.on_the.week_number);
  return year_rule;
}
/* ---------RRule helpers End----------- */

/* --------- Timezone helpers ----------- */
const get_timezone = computed(() => auth_store.logged_in_user_details?.timezone || dayjs.tz.guess());

function gettzid(rruleString) {
  if (!rruleString)
    return get_timezone.value;
  const tzidMatch = rruleString.match(/DTSTART;TZID=([^:]+):/);
  if (tzidMatch?.[1])
    return tzidMatch[1];
  return null;
}

/* --------- Timezone helpers end ----------- */

const rrule = computed(() => {
  if (!form_data.value)
    return null;

  let recurSet = {};
  if (props.schedule?.rrule) {
    recurSet.dtstart = RRule.fromString(props.schedule.rrule).options.dtstart;
    const tzid = gettzid(props.schedule.rrule);
    if (tzid)
      recurSet.tzid = tzid;
  }
  else {
    // set dtstart to 8:00 AM in user's app timezone
    recurSet.dtstart = new Date(dayjs.tz(`${dayjs().format('YYYY-MM-DD')} 08:00:00`, get_timezone.value));
    recurSet.tzid = get_timezone.value;
  }

  recurSet.interval = Number(form_data.value?.interval);
  if (form_data.value.repeatation.repeat === 'Repeat until')
    recurSet.until = form_data.value.repeatation.date;
  else if (form_data.value.repeatation.repeat === 'Repeat for')
    recurSet.count = Number(form_data.value.repeatation.count);

  switch (form_data.value.recurrence_frequency) {
    case 'Weekly':
      recurSet.freq = RRule.WEEKLY;
      recurSet.byweekday = setWeekRule();
      break;
    case 'Monthly':
      recurSet = {
        ...recurSet,
        ...setMonthRule(),
        freq: RRule.MONTHLY,
      };
      break;
    case 'Annually':
      recurSet = {
        ...recurSet,
        ...setYearRule(),
        freq: RRule.YEARLY,
      };
      break;
    case 'Daily':
      recurSet.freq = RRule.DAILY;
      break;
  }
  return new RRule(recurSet);
});

const dates = computed(() => {
  const today = new Date(dayjs.tz(`${dayjs().format('YYYY-MM-DD')} 08:00:00`, get_timezone.value));
  if (!(rrule.value?.after(today, true) && form_data.value))
    return {};

  const new_due_date = dayjs(rrule.value.after(today, true));
  let new_start_date = props.start_date ? dayjs(props.start_date) : null;
  if (form_data.value.duration)
    new_start_date = new_due_date.subtract(form_data.value.duration, 'day');

  const next_due_date = dayjs(rrule.value.after(new Date(new_due_date.toISOString())));
  const next_start_date = form_data.value.duration ? next_due_date.subtract(form_data.value.duration, 'day') : null;

  const creation_date = dayjs.max(next_due_date.subtract(form_data.value.days_before, 'day'), dayjs().add(1, 'hour'));

  return {
    new_due_date: dayjs(new_due_date).endOf('day'),
    new_start_date: new_start_date ? dayjs(new_start_date).startOf('day') : null,
    creation_date: dayjs(creation_date, get_timezone.value),
    next_start_date: next_start_date ? dayjs(next_start_date).startOf('day') : null,
    next_due_date: dayjs(next_due_date).endOf('day'),
  };
});

// Rrule payload generator
function setRecurringRule() {
  const data = {
    schedule: {
      rrule: rrule.value.toString(),
      create_new_ticket: true,
      recur_when: form_data.value.recur_when,
      skip_occurrence: false,
      ...((form_data.value.recur_when === 6 || form_data.value.recur_when === 'on_schedule') && { days_before: Number(form_data.value.days_before) }),
    },
    rrule: rrule.value.toString(),
    duration: form_data.value.duration,
  };
  return data;
}

function numberInputValidator(name, e) {
  if (e.target.value) {
    const value = Number(e.target.value);
    let value_to_load = DEFAULT_NUM_VALUES[name];
    if (value >= value_to_load && Number.isInteger(value))
      value_to_load = value;
    if (name === 'count')
      form$.value.elements$.repeatation.children$.count.update(value_to_load);
    else
      form$.value.elements$[name].update(value_to_load);
  }
}

function blurHandler(name) {
  const value_to_load = DEFAULT_NUM_VALUES[name];
  if (name === 'count')
    !form$.value.data.repeatation.count && form$.value.elements$.repeatation.children$.count.update(value_to_load);
  else if (!form$.value.data[name])
    form$.value.elements$[name].update(value_to_load);
}

const form_has_errors = computed(() => !!form$.value?.hasErrors);

// Exposing data and methods to parent
defineExpose({ form_data, setRecurringRule, has_errors: form_has_errors });
</script>

<template>
  <Vueform
    ref="form$"
    v-model="form_data"
    sync
    size="sm"
    :columns="{
      default: { container: 12, label: 3, wrapper: 12 },
      sm: { container: 12, label: 3, wrapper: 12 },
      md: { container: 12, label: 3, wrapper: 12 },
    }"
    :format-load="(data) => data"
    :should_validate_on_mount="false"
    :display-errors="false"
  >
    <SelectElement
      class="mb-3"
      name="recurrence_frequency"
      :label="$t('Frequency')"
      :can-clear="false"
      :can-deselect="false"
      :native="false"
      :items="[$t('Daily'), $t('Weekly'), $t('Monthly'), $t('Annually')]"
      @select="loadDefault"
    />
    <TextElement
      class="mb-3"
      name="interval"
      input-type="number"
      rules="numeric"
      :label="$t('Every')"
      @input="$event => numberInputValidator('interval', $event)"
      @blur="$event => blurHandler('interval')"
      @change="form$.el$('days_before').validate()"
    >
      <template #addon-after>
        {{ form_data?.recurrence_frequency ? $t(frequency_map[form_data.recurrence_frequency]) : '' }}
      </template>
    </TextElement>
    <CheckboxgroupElement
      class="mb-3"
      name="selected_weekdays"
      view="tabs"
      :label="$t('On days')"
      :items="weekday_items"
      :conditions="[['recurrence_frequency', 'Weekly']]"
    />
    <ObjectElement
      class="mb-3"
      name="schedule_group"
      view="tabs"
      :label="$t('Schedule')"
      :items="schedule_items"
      :conditions="[['recurrence_frequency', 'Monthly']]"
    >
      <RadiogroupElement
        name="freq"
        :items="[
          { label: $t('On particular dates'), value: 'On particular dates' },
          { label: $t('On the'), value: 'On the' },
        ]"
      />
      <CheckboxgroupElement
        class="mb-3"
        name="month_dates"
        view="tabs"
        :conditions="[['schedule_group.freq', 'On particular dates']]"
        :items="schedule_items"
        :override-classes="{
          CheckboxgroupElement: {
            wrapper: override_class_map.Monthly.wrapper,
            wrapper_sm: '',
          },
          CheckboxgroupCheckbox: {
            wrapper: 'flex items-center justify-center w-full text-sm font-semibold text-gray-700 cursor-pointer',
            container: override_class_map.Monthly.container,
            wrapper_first_sm: '',
            wrapper_last_sm: '',
            wrapper_unselected: '',
            wrapper_selected: 'bg-gray-100',
          },
        }"
      />
      <ObjectElement
        name="on_the"
        :conditions="[['schedule_group.freq', 'On the']]"
      >
        <SelectElement
          name="week_number"
          :native="false"
          :can-clear="false"
          :can-deselect="false"
          :items="[
            { label: $t('1st'), value: 1 },
            { label: $t('2nd'), value: 2 },
            { label: $t('3rd'), value: 3 },
            { label: $t('4th'), value: 4 },
            { label: $t('Last'), value: -1 },
          ]"
          :columns="{
            default: 4,
            sm: 4,
            md: 4,
          }"
        />
        <SelectElement
          class="ml-4"
          name="week_day"
          label-prop="name"
          :native="false"
          :can-clear="false"
          :can-deselect="false"
          :items="weekday_items"
          :columns="{
            default: 8,
            sm: 8,
            md: 8,
          }"
        />
      </ObjectElement>
    </ObjectElement>
    <CheckboxgroupElement
      class="mb-3"
      name="month_of_year"
      view="tabs"
      :label="$t('Schedule')"
      :items="schedule_items"
      :conditions="[['recurrence_frequency', 'Annually']]"
      :override-classes="{
        CheckboxgroupElement: {
          wrapper: override_class_map.Annually.wrapper,
          wrapper_sm: '',
        },
        CheckboxgroupCheckbox: {
          wrapper: 'flex items-center justify-center w-full text-sm font-semibold text-gray-700 cursor-pointer',
          container: override_class_map.Annually.container,
          wrapper_first_sm: '',
          wrapper_last_sm: '',
          wrapper_unselected: '',
          wrapper_selected: 'bg-gray-100',
        },
      }"
    />
    <ObjectElement name="on_the" :label="$t('On the')" :conditions="[['recurrence_frequency', 'Annually']]">
      <SelectElement
        name="week_number"
        :native="false"
        :can-clear="false"
        :can-deselect="false"
        :items="[
          { label: $t('1st'), value: 1 },
          { label: $t('2nd'), value: 2 },
          { label: $t('3rd'), value: 3 },
          { label: $t('4th'), value: 4 },
          { label: $t('Last'), value: -1 },
        ]"
        :columns="{
          default: 4,
          sm: 4,
          md: 4,
        }"
      />
      <SelectElement
        class="ml-4"
        name="week_day"
        label-prop="name"
        :native="false"
        :can-clear="false"
        :can-deselect="false"
        :items="weekday_items"
        :columns="{
          default: 8,
          sm: 8,
          md: 8,
        }"
      />
    </ObjectElement>
    <SelectElement
      class="mb-3"
      name="recur_when"
      :label="$t('Repeat')"
      :can-clear="false"
      :can-deselect="false"
      :native="false"
      :items="recur_when.options"
    />
    <TextElement
      class="mb-3"
      name="duration"
      input-type="number"
      :description="$t('The start date will be set to the mentioned number of days before the schedule/due-date')"
      rules="numeric"
      :label="$t('Duration')"
      :addons="{ after: 'days' }"
      @input="$event => numberInputValidator('duration', $event)"
      @blur="$event => blurHandler('duration')"
    />
    <TextElement
      class="mb-3"
      name="days_before"
      input-type="number"
      :label="$t('Days before')"
      :conditions="[[['recur_when', '==', 6], ['recur_when', '==', 'on_schedule']]]"
      :description="`${$t('Choose the number of days before the schedule to create the')} ${module}`"
      :addons="{ after: 'days' }"
      :rules="['gte:duration', 'numeric', daysBeforeValidator]"
      @input="$event => numberInputValidator('days_before', $event)"
      @blur="$event => blurHandler('days_before')"
    />
    <ObjectElement
      name="repeatation"
      :label="$t('Frequency')"
    >
      <RadiogroupElement
        name="repeat"
        :items="[
          { label: $t('Repeat forever'), value: 'Repeat forever' },
          { label: $t('Repeat for'), value: 'Repeat for' },
          { label: $t('Repeat until'), value: 'Repeat until' },
        ]"
      />
      <TextElement
        name="count"
        input-type="number"
        :description="`${$t('Total number of times the')} ${$t(module)} ${$t('will be created')}`"
        :addons="{ after: 'times' }"
        rules="numeric"
        :conditions="[['repeatation.repeat', 'Repeat for']]"
        @input="$event => numberInputValidator('count', $event)"
        @blur="$event => blurHandler('count')"
      />
      <DateTimeElement
        name="date"
        :conditions="[['repeatation.repeat', 'Repeat until']]"
        :options="{
          format: 'dd/MM/yyyy',
          placeholder: $t('Select Date'),
          startTime: { hours: 23, minutes: 59 },
          minDate: new Date(dayjs.tz(dayjs().format('YYYY-MM-DD'), get_timezone)),
          timezone: get_timezone,
        }"
      />
    </ObjectElement>
  </Vueform>
  <div
    class="text-xs p-2 mt-4 rounded-lg border border-primary-200 bg-primary-25"
  >
    <p v-if="dates.new_start_date">
      {{ $t(`The start date and due date for the current ${module} will be set to`) }}
      <span class="font-medium">{{ $date(dates.new_start_date, 'DATE_ORDINAL') }}</span>
      {{ $t('and') }}
      <span class="font-medium">{{ $date(dates.new_due_date, 'DATE_ORDINAL') }}</span>
      {{ $t('respectively') }}
    </p>
    <p v-else>
      {{ $t(`The due date for the current ${module} will be set to`) }} <span class="font-medium">{{ $date(dates.new_due_date, 'DATE_ORDINAL') }}.</span>
    </p>
    <p
      v-if="dates.creation_date?.isValid() || dates.next_start_date?.isValid() || dates.next_due_date?.isValid()"
      class="mt-2"
    >
      {{ $t(`And the next ${module} will be scheduled as follows`) }}:
    </p>
    <p v-if="dates.creation_date?.isValid()" class="mt-4">
      {{ $t('Creation date') }}: <span class="font-medium"> {{ $date(dates.creation_date, 'DATE_ORDINAL') }} </span>
    </p>
    <p v-if="dates.next_start_date?.isValid()" class="mt-2">
      {{ $t('Start date') }}: <span class="font-medium"> {{ $date(dates.next_start_date, 'DATE_ORDINAL') }} </span>
    </p>
    <p v-if="dates.next_due_date?.isValid()" class="mt-2">
      {{ $t('Due date') }}: <span class="font-medium"> {{ $date(dates.next_due_date, 'DATE_ORDINAL') }} </span>
    </p>
    <p class="mt-2">
      <span class="font-medium">{{ $t('Note') }}</span>: {{ $t('All dates are represented in') }} <span class="font-medium">{{ `GMT ${dayjs(new Date()).tz(gettzid(schedule?.rrule) || get_timezone).format('Z')}` }}</span>
    </p>
  </div>
</template>

<style scoped>
  :deep(input[type="number"]) {
    -moz-appearance: auto !important;
    appearance: auto !important;
  }
</style>

<style lang="scss">
.annual_border_class {
  border-top: 1px solid #D0D5DD;
  border-left: 1px solid #D0D5DD;
  &:nth-child(4n) {
    border-right: 1px solid #D0D5DD;
  }
  &:nth-child(n+9) {
    border-bottom: 1px solid #D0D5DD;
  }
  &:nth-child(1) {
    border-top-left-radius: 8px;
    div {
      border-top-left-radius: 8px;
    }
  }
  &:nth-child(4) {
    border-top-right-radius: 8px;
    div {
      border-top-right-radius: 8px;
    }
  }
  &:nth-child(9) {
    border-bottom-left-radius: 8px;
    div {
      border-bottom-left-radius: 8px;
    }
  }
  &:nth-child(12) {
    border-bottom-right-radius: 8px;
    div {
      border-bottom-right-radius: 8px;
    }
  }
}
.month_border_class {
  border-top: 1px solid #D0D5DD;
  border-left: 1px solid #D0D5DD;
  &:nth-child(7n) {
    border-right: 1px solid #D0D5DD;
  }
  &:nth-child(n+29) {
    border-bottom: 1px solid #D0D5DD;
  }
  &:nth-child(1) {
    border-top-left-radius: 8px;
    div {
      border-top-left-radius: 8px;
    }
  }
  &:nth-child(7) {
    border-top-right-radius: 8px;
    div {
      border-top-right-radius: 8px;
    }
  }
  &:nth-child(29) {
    border-bottom-left-radius: 8px;
    div {
      border-bottom-left-radius: 8px;
    }
  }
  &:nth-child(35) {
    border-bottom-right-radius: 8px;
    div {
      border-bottom-right-radius: 8px;
    }
  }
}
</style>
