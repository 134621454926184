<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { keyBy } from 'lodash-es';
// --------------------------------- Imports -------------------------------- //
import { useRoute } from 'vue-router';
import { useFormDetailStore } from '~/forms/store/form-detail.store';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import { useFormsStore } from '~/forms/store/forms.store';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  header: {
    type: String,
    required: true,
  },
  sub_header: {
    type: String,
    default: '',
  },
  formula_html: {
    type: String,
    required: true,
  },
});

// ---------------------------------- Emits --------------------------------- //
const emit = defineEmits(['close']);

// ----------------------- Variables - Pinia - consts ----------------------- //
const forms_store = useFormsStore();
const form_detail_store = useFormDetailStore();
const form_template_detail_store = useFormTemplateDetailStore();
const route = useRoute();
const parser = ref(null);

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const error_msg_map = {
  '#ERROR!': 'An error occurred',
  '#DIV/0!': 'Divide by zero error',
  '#NAME?': 'Invalid function name or variable name',
  '#N/A': 'Value is not available to the formula',
  '#NUM!': 'Invalid number',
  '#VALUE!': 'Formula arguments is of the wrong type',
};

// ------------------------ Variables - Local - refs ------------------------ //
const form$ = ref(null);
const is_calculating = ref(false);
const calculated_value = ref('');
const calculation_error = ref('');

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //

// -------------------------------- Functions ------------------------------- //
async function getFormList() {
  await forms_store.set_forms({
    query: {
      page_size: Number.MAX_SAFE_INTEGER,
      parent_form_uid: route.params.template_uid,
      submission_status: ['submitted', 'draft'],
    },
  });
  return forms_store?.forms;
}

function convertHtmlToPlainText(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  doc.querySelectorAll('.formula-suggestion, .tag-suggestion').forEach((element) => {
    const dataId = element.getAttribute('data-id');

    element.replaceWith(dataId);
  });

  let plainText = doc.body.textContent;
  plainText = plainText.replace(/\s+/g, '');

  return plainText;
}

function getAllFields() {
  const all_section_fields = form_template_detail_store.sections.filter(section => section.type === 'default').flatMap(section => section.fields.map(field => ({
    ...field,
  }))).filter(field => ['short_text', 'number', 'money', 'date_time'].includes(field?.properties?.type) && field.status === 'active');

  return all_section_fields;
}

async function fetchFormValue() {
  await form_detail_store.set_form({ id: form$.value.data.form_uid });

  return form_detail_store.field_values;
}

async function calculateValue() {
  is_calculating.value = true;

  const field_values = await fetchFormValue();
  const all_fields = getAllFields();
  const field_slug_map = keyBy(all_fields, 'slug');

  Object.keys(field_slug_map).forEach((field_slug) => {
    // INFO: We can formatData here if required
    if (field_slug_map[field_slug]?.uid)
      parser.value.setVariable(field_slug, field_values[field_slug_map[field_slug].uid]);
  });

  const parsed_formula = convertHtmlToPlainText(props.formula_html);
  const response = parser.value.parse(parsed_formula);

  calculated_value.value = response.result;
  calculation_error.value = response.error;
  is_calculating.value = false;
}

function loadParserEvents(parser) {
  parser.on('callVariable', (slug_name, done) => {
    const value = parser.getVariable(slug_name);
    done(value || '');
  });
}

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //

onMounted(async () => {
  if (!parser.value) {
    const { Parser } = await import('hot-formula-parser');
    parser.value = new Parser();
    loadParserEvents(parser.value);
  }
});
</script>

<template>
  <HawkModalContainer content_class="rounded-lg w-[700px]">
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #header>
            <div
              class="flex items-start p-6 border-b border-b-gray-200 justify-between text-lg font-semibold text-gray-800"
            >
              <div class="flex items-start">
                <div class="flex flex-col justify-start">
                  {{ header }}
                  <span v-if="sub_header" class="font-normal text-sm text-gray-600">
                    {{ sub_header }}
                  </span>
                </div>
              </div>
              <div class="flex font-normal items-center justify-center -m-2">
                <div
                  class="text-gray-600 rounded-md cursor-pointer flex justify-center items-center p-2 ml-3 hover:bg-gray-50"
                  @click="$emit('close')"
                >
                  <IconHawkXClose class="w-6 h-6 text-gray-500 hover:text-gray-900" />
                </div>
              </div>
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent :is_scroll="false">
          <div class="flex flex-col gap-6">
            <SelectElement
              name="form_uid"
              rules="required"
              :items="getFormList"
              value-prop="uid"
              label-prop="name"
              search
              :native="false"
              :label="$t('Select form')"
              :placeholder="$t('Choose a form')"
              :info="$t('Please ensure the selected form has field value required to calculate the formula')"
              :no-options-text="$t('No forms available')"
            />
            <div v-if="form$?.data?.form_uid" class="text-sm font-medium text-gray-700 grid grid-cols-12 items-center">
              <div class="col-span-4">
                {{ $t('Output value') }}
              </div>
              <div class="col-span-8">
                <template v-if="is_calculating">
                  <HawkLoader class="!block !m-0" />
                </template>
                <template v-else>
                  <span v-if="calculation_error" class="flex gap-2 items-center text-error-500">
                    <IconHawkAlertTriangle class="w-4 h-4" /> {{ error_msg_map[calculation_error] || error_msg_map['#ERROR!'] }}
                  </span>
                  <span v-else-if="calculated_value || calculated_value === 0">
                    {{ calculated_value }}
                  </span>
                  <HawkButton v-else type="link" @click="calculateValue">
                    {{ $t('Calculate value') }}
                  </HawkButton>
                </template>
              </div>
            </div>
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton class="mr-4" @click="emit('close')">
                  {{ $t('Close') }}
                </HawkButton>
              </div>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
