<script setup>
import { cloneDeep, isEmpty } from 'lodash-es';
import { all_countries } from '~/common/constants/all-countries.js';
import { useFormBuilderAllFields } from '~/forms/composables/form-builder-all-fields.composable.js';
import { formatAutoNumbering } from '~/forms/composables/form-builder-auto-number-composable';
import { useFieldConfiguration } from '~/forms/composables/form-builder-field-schema.composable.js';
import { createListOnPaste } from '~/system-model/utils/helper';

const props = defineProps({
  options: {
    type: Object,
  },
  step_number: {
    type: Number,
  },
  previous_step_number: {
    type: Number,
  },
});

const emits = defineEmits(['close']);
const form_template_detail_store = inject('form_template_detail_store');
const $track_event = inject('$track_event');
const { field_map } = useFormBuilderAllFields();

const form$ = ref(null);
const is_loading = ref(false);
const formulaSuggestions = ref([]);

const field = computed({ get: () => props.options.field, set: () => { } });
const section = computed({ get: () => props.options.section, set: () => { } });
const counter = computed({ get: () => props.options.counter, set: () => { } });

provide('selected-field', field);
const $services = inject('$services');
const $toast = inject('$toast');

async function loadFormulaParser() {
  try {
    const { SUPPORTED_FORMULAS } = await import('hot-formula-parser');

    formulaSuggestions.value = SUPPORTED_FORMULAS?.map(formula_name => ({
      id: formula_name,
      label: formula_name,
    }));
  }
  catch (error) {
    logger.error('Error loading formula parser:', error);
    formulaSuggestions.value = [];
  }
};

onMounted(async () => {
  if (field.value.config.type === 'formula') {
    is_loading.value = true;
    await loadFormulaParser();
    is_loading.value = false;
  }
});

const field_configuration_map = computed(() => useFieldConfiguration(
  field.value,
  'basic',
  section.value,
  { step_number: props.step_number, previous_step_number: props.previous_step_number, formula_suggestions: formulaSuggestions.value },
));

let old_field = cloneDeep(props.options.field);

async function save(data) {
  if (!isEmpty(data.condition) && data.condition?.add_condition) {
    data.condition.conditions = {
      [data.condition.type === 'OR' ? 'any' : 'all']: data.condition.rules,
    };
  }
  else {
    data.condition = {};
  }

  try {
    if (field.value.uid) {
      const updated_field = await form_template_detail_store.update_field(field.value.uid, { data, section_index: props.options.section_index, field_index: props.options.field_index });
      Object.assign(field.value, updated_field);
    }
    else { await add_field(data); }
    old_field = field.value;
    emits('close');
  }
  catch (e) {
    $toast({ text: e?.data?.message || 'Field Updating failed!', type: 'error' });
  }
}

async function add_field(form) {
  try {
    const fields = section.value.fields;
    const response = await $services.forms.post({
      attribute: 'fields',
      body: {
        field: { ...field.value, ...form },
      },
    });
    fields.push(response.data.field);
    form_template_detail_store.reset_form_template(section.value.uid);
    $track_event('add_field', {
      type: field_map[response.data.field?.properties?.type].name,
      template_used: response.data.field?.is_template,
    });
  }
  catch (e) {
    $toast({ text: e?.data?.message || 'Field Updating failed!', type: 'error' });
  }
}

function resetField() {
  if (!old_field?.uid) {
    form_template_detail_store.reset_form_template(section.value.uid);
  }
  else {
    const index = section.value.fields.findIndex(field => field.uid === old_field.uid);
    section.value.fields[index] = old_field;
  }
}
function formatData(data, type) {
  if (type === 'phone') {
    const country = all_countries.find(country => country.iso2 === data?.config?.iso);
    if (country)
      data.config.code = country.dialCode;
    return data;
  }

  if (type === 'members' && data.config.allowed_members?.members?.length) {
    data.config.allowed_members.members = data.config.allowed_members.members.map(member_detail => ({
      member: member_detail.member,
      uid: member_detail.uid,
    }));
    return data;
  }
  return type === 'auto_numbering' ? { ...data, auto_number_data: formatAutoNumbering(data) } : data;
}

function autoSizeTextArea() {
  setTimeout(() => {
    form$.value?.elements$?.meta.children$?.name?.autosize();
    form$.value?.elements$?.meta.children$?.description?.autosize();
    form$.value?.elements$?.config?.children$?.placeholder?.autosize?.();
  }, 50);
}

function autofocusInputs() {
  nextTick(() => {
    if (props.options.focus_description)
      form$.value?.elements$?.meta.children$?.description?.input?.focus();
    else
      form$.value?.elements$?.meta.children$?.name?.input?.focus();
  });
}

watch(field, () => {
  resetField();
  old_field = cloneDeep(props.options.field);
  autofocusInputs();
  autoSizeTextArea();
});

watch(counter, () => {
  autofocusInputs();
});

function formMounted() {
  autofocusInputs();
  autoSizeTextArea();
}

async function pasteHandler(event) {
  const columns = createListOnPaste(event);
  const items = columns.map((item) => {
    return { name: item.split(/[\t,]+/)[0] };
  });
  const merged_component = [...(form$.value.data.config?.filter(c => c.name) || []), ...items];
  const config$ = form$.value.el$('config');
  config$.clear();
  setTimeout(() => {
    config$.update(merged_component);
  }, 10);
}
onUnmounted(() => {
  resetField();
});
</script>

<template>
  <div>
    <div class="border rounded-lg sticky top-16">
      <div>
        <div class="flex justify-between items-center p-4">
          <div v-if="field.uid" class="font-semibold text-lg">
            {{ $t('Edit Field') }}
          </div>
          <div v-else class="font-semibold text-lg">
            {{ $t('Add Field') }}
          </div>
          <hawk-button type="text" @click="emits('close')">
            <icon-hawk-x-close />
          </hawk-button>
        </div>
        <HawkLoader v-if="is_loading" />
        <div v-else :key="field" class="overflow-y-auto max-h-[calc(100vh-13rem)] scrollbar p-4">
          <Vueform
            ref="form$"
            v-model="field"
            :presets="['text_area_without_enter']"
            :schema="field_configuration_map[field.properties.type]"
            :form-data="form$ => formatData(form$.data, field.properties.type)"
            :sync="true"
            :columns="{
              container: 12,
              label: 4,
              wrapper: 12,
            }"
            :add-classes="{ FormElements: { container: 'gap-[20px]' }, ObjectElement: { wrapper: 'gap-[20px]' } } "
            :remove-classes="{ FormElements: { container_sm: 'form-gap-y-gutter-sm' }, ObjectElement: { wrapper_sm: 'form-gap-gutter-sm' } }"
            :endpoint="save"
            :display-errors="false"
            size="sm"
            @paste="field.properties.type !== 'info' && pasteHandler"
            @keypress.enter.prevent="form$.submit()"
            @reset="emits('close')"
            @mounted="formMounted"
          />
        </div>
      </div>
    </div>
  </div>
</template>
