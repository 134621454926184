<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import DOMPurify from 'dompurify';
import { cloneDeep, debounce, keyBy, omitBy, pick, sortBy } from 'lodash-es';
import { computed, onBeforeUnmount, onMounted } from 'vue';
import { useModal } from 'vue-final-modal';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useI18nStore } from '~/common/stores/i18n.store';
import { removeEmptyKeys, sleep } from '~/common/utils/common.utils.js';
import { load_js_css_file } from '~/common/utils/load-script.util';
import EditFieldPopup from '~/terra/components/table/edit-column-name-handson-table.vue';
import TableHeader from '~/terra/components/table/terra-table-header.vue';
import { useTerraStore } from '~/terra/store/terra.store';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable';

const props = defineProps({
  active_menu: {
    type: String,
    default: '',
  },
  is_table_active: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['openCreationPopup', 'saveView', 'close', 'openPivotTable']);
const edit_field_popup = useModal({
  component: EditFieldPopup,
  attrs: {
    onClose() {
      edit_field_popup.close();
    },
  },
});
const delete_popup = useModal({
  component: HawkDeletePopup,
  attrs: {
    onClose() {
      delete_popup.close();
    },
  },
});
const state = reactive({
  is_loading: false,
  is_saving: false,
  affected_rows: {},
  data: {},
  all_checked: false,
  sorting_type: 'Horizontal',
  all_rows_selected: false,
  container_height: '60%',
  last_checked_index: -1,
  expanded: false,
  sorting_order: 'asc',
  order: { 'selected': 1, 'Fly to': 2, 'uid': 3, 'name': 4, 'Feature type': 5, 'description': 6 },
  columns: [{
    data: 'selected',
    type: 'checkbox',
    width: 44,
    renderer: 'checkbox-with-subtle-cell-border',
    className: 'htCenter htMiddle',
    disableVisualSelection: true,
    manualColumnResize: false,
    multiColumnSorting: {
      compareFunctionFactory(sortOrder) {
        return (a, b) => (sortOrder === 'asc' ? 1 : -1) * (b - a);
      },
    },
  }, {
    data: 'Fly to',
    type: 'checkbox',
    width: 30,
    renderer: 'fly-to-feature-icon',
    className: 'htCenter htMiddle',
    disableVisualSelection: true,
    manualColumnResize: false,
    multiColumnSorting: {
      headerAction: false,
    },
  }],
  features: [],
  show_save_changes: false,
  dropdown_open: false,
  is_map_select: true,
  centroid_map: {},
  show_filter_loader: false,
  show_close_loader: false,
  table_initialized: false,
});
const table_instance = ref(null);
const $t = inject('$t');
const $services = inject('$services');
const $toast = inject('$toast');
const i18n_store = useI18nStore();
const terra_store = useTerraStore();
const { static_keys } = useTerraHelperComposable();
const can_modify_feature = computed(() => terra_store.check_permission('modify_feature_properties'));
const features_hash = computed(() => keyBy(terra_store.features_on_map, item => item.properties.uid));
const table_data = computed(() => {
  return Object.values(state.data);
});
let turf;
watch(() => terra_store.selected_features, (val) => {
  try {
    // To deselect all the previous selected rows and keep only the ones which are in the selected_features array
    if (state.is_map_select) {
      table_data.value.forEach((row) => {
        state.data[row.uid].selected = false;
      });
      if (val.length) {
        val.forEach((feature) => {
          if (state.data[feature.properties.uid])
            state.data[feature.properties.uid].selected = true;
        });
      }
      if (table_instance.value) {
        const selected_column_values = table_instance.value?.getDataAtCol(0) || [];
        state.all_rows_selected = selected_column_values.length && selected_column_values.every(val => val);
        table_instance.value.updateSettings({ data: table_data.value });
      }
    }
    state.is_map_select = true;
  }
  catch (error) {
    logger.error(error);
  }
});
watch(() => terra_store.features_on_map, async (features) => {
  if (table_instance.value) {
    if (Object.keys(state.data).length !== features.length || !features.every(f => !!state.data[f.properties.uid])) {
      await getFeaturesData();
    }

    updateTableSettings();
    terra_store.update_symbols_on_the_map_flag += 1; // Exception: Updating here apart from update_map_features_and_polygon
  }
});
watch(() => ({ active_menu: props.active_menu }), async (curr, prev) => {
  if (state.table_initialized && (((curr.active_menu === '' && prev.active_menu !== '') || (prev.active_menu === '' && curr.active_menu !== ''))))
    await destroyAndReconstructTable();
});
watch(() => i18n_store.current_lang, () => {
  table_instance.value.render();
});
function getSourceDataAtRow(hot, row) {
  const source_row = {};
  // getDataAtRow return data in array of arrays
  hot.getDataAtRow(row).forEach((val, i) => {
    if (state.columns?.[i]?.data)
      source_row[state.columns[i].data] = val;
  });
  return source_row;
}
const updateSearch = debounce((e) => {
  if (table_instance.value) {
    terra_store.table_state.search = e;
    const search = table_instance.value.getPlugin('search');
    const result = search.query(e).reduce((acc, curr) => {
      if (curr.col > 1)
        acc[curr.row] = true;
      return acc;
    }, {});
    const features = [];
    const hidden_rows = table_instance.value.getData().reduce((acc, val, i) => {
      if (!result[i] && e.length)
        acc.push(i);
      else
        features.push(features_hash.value[getSourceDataAtRow(table_instance.value, i).uid]);
      return acc;
    }, []);
    table_instance.value.updateSettings({
      hiddenRows: {
        rows: hidden_rows,
      },
    });
    table_instance.value.render();
    terra_store.update_map_features_and_polygon({ features });
    terra_store.table_state.features = cloneDeep(features);
  }
}, 500);
async function checkSortSelectedFeatures() {
  const column_sort_plugin = table_instance.value.getPlugin('multiColumnSorting');
  state.is_loading = true;
  await sleep(200);
  if (terra_store.table_state.should_sort_selected_features) {
    terra_store.table_state.sort = [{ column: 0, sortOrder: 'asc' }];
    column_sort_plugin.sort(terra_store.table_state.sort);
  }
  else {
    terra_store.table_state.sort = terra_store.table_state.sort.filter(val => val.column !== 0);
    column_sort_plugin.sort(terra_store.table_state.sort);
  }
  state.is_loading = false;
}
async function destroyAndReconstructTable() {
  if (table_instance.value) {
    table_instance.value.destroy();
    table_instance.value = null;
    // Remove the element from the DOM
    const container = document.getElementById('bulk-edit-table');
    container.innerHTML = '';
  }

  state.is_loading = true;
  await sleep(1000);
  loadHandsOn();
  applyFilters();
  await checkSortSelectedFeatures();
  await sleep(500);

  state.is_loading = false;
}
function checkboxRenderer(hotInstance, td, row) {
  // eslint-disable-next-line prefer-rest-params
  Handsontable.renderers.CheckboxRenderer.apply(this, arguments);

  const source_row = getSourceDataAtRow(hotInstance, row);
  const feature = features_hash.value[source_row.uid];
  const feature_type = terra_store.feature_types?.[feature?.properties?.featureTypeId];
  let color = '';
  if (terra_store.filters_state.active_filter)
    color = feature?.properties?.color;
  else
    color = feature_type?.properties?.color;
  td.style.borderLeft = `5px solid ${color || 'black'}`;
}
function flyToRenderer(hotInstance, td) {
  td.innerHTML = '<i>&#8594;</i>';
  td.style.verticalAlign = 'middle';
}

async function getFeaturesData() {
  state.is_loading = true;
  try {
    terra_store.table_state.features = cloneDeep(terra_store.features_on_map);
    state.features = terra_store.features_on_map;
    state.show_save_changes = false;
    const { feature_name_hash, all_fields_map } = terra_store.sm_instances_map;
    const all_fields = Object.values(all_fields_map);
    const if_sm_properties_present = all_fields.length;
    const sm_properties_schema = {};
    all_fields.forEach((item) => {
      sm_properties_schema[`smProperties_${item.name}`] = null;
    });

    if (state.features.length) {
      const selected_features_map = keyBy(terra_store.selected_features, f => f.properties.uid);
      const allColumnsSet = new Set();
      const newData = {};
      state.data = {};
      state.features.forEach((f) => {
        const uid = f.properties.uid;
        state.centroid_map[f.properties.uid] = f.geometry?.coordinates?.length ? turf.centroid(f).geometry.coordinates : {};
        const featureType = terra_store.feature_types?.[f?.properties?.featureTypeId]?.name || '';
        const extraProperties = {};
        const properties = terra_store.get_feature_properties(f, {});
        Object.entries(properties).forEach(([key, value]) => {
          if (!key.startsWith('_') && !static_keys.includes(key)) {
            extraProperties[key] = String(value || '');
            allColumnsSet.add(key);
          }
        });
        newData[uid] = {
          'selected': !!selected_features_map[uid],
          ...pick(f.properties, ['uid', 'name', 'description']),
          'Feature type': featureType,
          ...extraProperties,
          ...(if_sm_properties_present ? sm_properties_schema : {}),
        };
        const sm_properties = feature_name_hash[f.properties.name];
        if (sm_properties) {
          Object.keys(sm_properties).forEach((sm_property_key) => {
            newData[uid][`smProperties_${sm_property_key}`] = sm_properties[sm_property_key];
          });
        }
      });

      const all_columns = Array.from(allColumnsSet);
      const defaultObject = Object.fromEntries(all_columns.map(key => [key, '']));
      for (const uid in newData) {
        state.data[uid] = {
          ...(state.data[uid] || {}),
          ...defaultObject,
          ...newData[uid],
        };
      }

      sortData(false);
      updateColumnsOrder(false);
      await sleep(2000);
    }
    else {
      state.data = {};
      state.columns = state.columns.slice(0, 2);
      state.all_rows_selected = false;
    }
  }
  catch (error) {
    logger.error(error);
  }
  state.is_loading = false;
}
function flyToFeature(feature) {
  // To select feature
  if (!state.data[feature.properties.uid].selected) {
    state.data[feature.properties.uid].selected = true;
    table_instance.value.render();
    updateSelectedFeatures();
  }

  // To select feature
  if (!state.data[feature.properties.uid].selected) {
    state.data[feature.properties.uid].selected = true;
    table_instance.value.render();
    updateSelectedFeatures();
  }

  const centroid = turf.centroid(feature);
  terra_store.map.flyTo({
    center: centroid.geometry.coordinates,
    zoom: 20,
    speed: 8,
    pitch: 0, // resets tilt on the map
    bearing: 0, // resets rotation on the map
  });
}
function loadHandsOn() {
  const container = document.getElementById('bulk-edit-table');
  let avoid_on_load_filter = true;
  Handsontable.renderers.registerRenderer(
    'checkbox-with-subtle-cell-border',
    checkboxRenderer,
  );
  Handsontable.renderers.registerRenderer(
    'fly-to-feature-icon',
    flyToRenderer,
  );
  Handsontable.renderers.registerRenderer(
    'attachment-renderer',
    attachmentRenderer,
  );
  Handsontable.renderers.registerRenderer(
    'sm-properties-renderer',
    smPropertiesRenderer,
  );

  table_instance.value = new Handsontable(container, {
    data: table_data.value,
    columns: state.columns,
    width: '100%',
    height: '100%',
    hiddenColumns: {
      columns: [2], // hiding uid
    },
    licenseKey: import.meta.env.VITE_APP_HOT_LICENSE_KEY,
    viewportRowRenderingOffset: 100,
    colHeaders(index) {
      if (index === 0) {
        return `<input type="checkbox" class="checker" style="appearance:auto;" ${
          state.all_rows_selected ? 'checked="checked"' : ''
        }>`;
      }
      else if (index === 1) {
        return '';
      }
      if (index === 3) {
        return $t('Name');
      }
      else if (index === 4) {
        return $t('Type');
      }
      else if (index === 5) {
        return $t('Description');
      }
      else if (state.columns[index]?.data.includes('smProperties_')) {
        return DOMPurify.sanitize(state.columns[index]?.data.replace('smProperties_', ''), { ALLOWED_TAGS: [] });
      }
      return DOMPurify.sanitize(state.columns[index]?.data, { ALLOWED_TAGS: [] });
    },
    stretchH: 'all',
    manualColumnFreeze: true,
    multiColumnSorting: true,
    rowHeights: 35,
    manualColumnResize: true,
    fixedColumnsLeft: 4,
    copyPaste: {
      columnsLimit: 10000,
      rowsLimit: 9999999,
    },
    exportFile: true,
    allowInsertColumn: true,
    search: {
      queryMethod(query, value) {
        if (query === '')
          return null; // Skip highlighting

        // Customize the search method as needed
        return (value || '').toString().toLowerCase().includes(query.toLowerCase());
      },
    },
    afterGetColHeader(col, TH) {
      if ([0, 1, 2].includes(col)) {
        const button = TH.children[0].children[0];
        button.parentNode.removeChild(button);
      }
    },
    // enable filtering
    filters: true,
    dropdownMenu: {
      items: {
        rename: {
          name: $t('Rename'),
          callback: updateColumns,
        },
        add_new_column_left: {
          name: $t('Insert left column'),
          callback: updateColumns,
        },
        add_new_column_right: {
          name: $t('Insert right column'),
          callback: updateColumns,
        },
        delete: {
          name: $t('Delete'),
          callback: updateColumns,
        },
        filter_by_condition: {},
        filter_by_value: {},
        filter_action_bar: {},
      },
    },
    async afterFilter(filters) {
      try {
        if (!avoid_on_load_filter) {
          state.show_filter_loader = true;
          if (JSON.stringify(filters) !== JSON.stringify(terra_store.table_state.filters))
            emit('saveView');
          terra_store.table_state.filters = filters;
          updateSearch(terra_store.table_state.search);
          // Sort table
          const column_sort_plugin = this.getPlugin('multiColumnSorting');
          column_sort_plugin.sort(terra_store.table_state.sort);
          await terra_store.map.once('idle');
          if (filters.length)
            terra_store.terra_track_events('Table column filtered');
          state.show_filter_loader = false;
        }
      }
      catch (err) {
        logger.log('🚀 ~ afterFilter ~ err:', err);
      }
    },
    beforeColumnSort(currentSortConfig, destinationSortConfigs) {
      terra_store.table_state.sort = destinationSortConfigs;
      terra_store.terra_track_events('Table sort columns');
      emit('saveView');
    },
    async afterOnCellMouseDown(event, coords) {
      if (coords.row <= -1 || coords.col >= 2)
        return;
      const rowData = getSourceDataAtRow(this, coords.row);
      event.stopPropagation();
      if (event.target.nodeName === 'I')
        flyToFeature(terra_store.features_hash[rowData.uid]);

      if (event.target.nodeName === 'INPUT') {
        shiftSelectRows(event, coords);
        if (coords.row > -1) {
          state.data[rowData.uid].selected = event?.target?.checked === false;
          if (!event.shiftKey)
            state.last_checked_index = coords.row;
        }

        updateSelectedFeatures();
      }
    },
    beforeOnCellMouseDown(event, coords) {
      if (coords.row > -1)
        return;
      if (['I', 'INPUT'].includes(event.target.nodeName) || event.target.className === 'checker')
        event.stopPropagation();
      // For disabling column selection
    },
    afterChange(changes, source) {
      if (source === 'loadData')
        return;
      // Find changes in table
      if (changes?.length && changes[0]?.[1] !== 'selected') {
        changes.forEach((item) => {
          // Strict equality is not needed here because of Table emitting string values for numbers as well
          // eslint-disable-next-line eqeqeq
          if ((item[2] ?? '') != item[3])
            state.affected_rows[item[0]] = true;
        });
        state.show_save_changes = state.show_save_changes || ((changes[0]?.[2] || '') !== (changes[0]?.[3] || ''));
      }
    },
    beforeDropdownMenuShow() {
      const selected_column = this.getSelected()[0][1];
      const menu_items = this.getPlugin('dropdownMenu').menu.menuItems;
      const column_details = this.getSettings().columns[selected_column];
      const is_disabled = column_details.data.startsWith('smProperties_') || ['name', 'description', 'Feature type'].includes(column_details.data);
      menu_items.forEach((item) => {
        if (['rename', 'delete'].includes(item.key))
          item.disabled = is_disabled;
      });
    },
  });
  avoid_on_load_filter = false;

  Handsontable.dom.addEvent(container, 'mouseup', (event) => {
    if (
      event.target.nodeName === 'INPUT'
      && event.target.className === 'checker'
    ) {
      state.all_rows_selected = !event.target.checked;
      const uids = table_instance.value.getDataAtCol(2);
      uids.forEach((uid) => {
        if (!state.data[uid])
          state.data[uid] = {};
        state.data[uid].selected = state.all_rows_selected;
      });
      table_instance.value.render();
      event.stopPropagation();
      updateSelectedFeatures();
    }
  });
  checkSortSelectedFeatures();
}
function shiftSelectRows(event, coords) {
  if (event.shiftKey && state.last_checked_index !== -1) {
    const start = state.last_checked_index > coords.row
      ? coords.row
      : state.last_checked_index;

    const end = state.last_checked_index > coords.row
      ? state.last_checked_index
      : coords.row;

    for (let index = start; index <= end; index++) {
      const element = getSourceDataAtRow(table_instance.value, index);
      state.data[element.uid].selected = true;
    }
  }
}
function updateTableSettings(options = {}) {
  if (table_instance.value) {
    table_instance.value.updateSettings({
      data: table_data.value,
      columns: state.columns,
      hiddenColumns: {
        columns: [2], // hiding uid
      },
    });
    applyFilters(options);
    table_instance.value.render(); // To apply filter
  }
}
function updateSelectedFeatures() {
  setTimeout(() => {
    const features = table_data.value.reduce((acc, curr) => {
      if (curr.selected)
        acc.push(features_hash.value[curr.uid]);
      return acc;
    }, []);
    state.is_map_select = false;
    terra_store.selected_features = features;
  }, 1000);
}
function updateColumns(key, selection) {
  if (!can_modify_feature.value) {
    $toast({
      text: $t('You don\'t have permissions to perform this action.'),
      type: 'info',
    });
    return;
  }
  const col = state.columns[selection[0].start.col].data;
  if (
    (['uid', 'name', 'description', 'Feature type', 'Layer', 'Sublayer'].includes(col) || col.startsWith('smProperties'))
    && !(col === 'description' && key === 'add_new_column_right')
    && !(col === 'Layer' && key.includes('add_new_column'))
    && !(col === 'Sublayer' && key.includes('add_new_column'))
  ) {
    $toast({
      text: 'The operation on the column cannot be executed.',
      type: 'info',
    });
    return;
  }
  if (key === 'rename' || key.includes('add_new_column')) {
    renameOrAddColumns(key, selection, col);
  }
  else {
    delete_popup.patchOptions({
      attrs: {
        header: $t('Delete values'),
        content: $t('Are you sure you want to delete the values'),
        onClose() {
          delete_popup.close();
        },
        confirm: async () => {
          state.columns = state.columns.filter(el => el.data !== col);
          const updated_data = {};
          table_data.value.forEach((properties) => {
            delete properties[col];
            updated_data[properties.uid] = properties;
          });
          state.data = updated_data;
          await handleColumnOperations(col, 'remove');
          updateFilterSortState('delete', { selected_column_index: selection[0].start.col });
          terra_store.terra_track_events('Column deleted');

          await sleep(200);
          updateTableSettings({ clear_filter_condition: true });
          delete_popup.close();
        },
      },
    });
    delete_popup.open();
  }
}
function renameOrAddColumns(key, selection, col) {
  edit_field_popup.patchOptions({
    attrs: {
      name: key === 'rename' ? col : '',
      onCancel() {
        edit_field_popup.close();
      },
      async on_save(name) {
        if (key === 'rename') {
          const updated_data = {};
          table_data.value.forEach((properties) => {
            properties[name] = properties[col];
            delete properties[col];
            updated_data[properties.uid] = properties;
          });
          state.data = updated_data;
          state.columns[selection[0].start.col].data = name;
          terra_store.terra_track_events('Column updated');
        }
        else {
          state.columns.splice(selection[0].start.col + (key.includes('left') ? 0 : 1), 0, { data: name, readOnly: false, type: 'text', className: 'htLeft htMiddle' });
          const updated_data = {};
          table_data.value.forEach((properties) => {
            properties[name] = '';
            updated_data[properties.uid] = properties;
          });
          state.data = updated_data;
          updateFilterSortState('add', { selected_column_index: selection[0].start.col, side: key });
          await handleColumnOperations(name, 'add');
          terra_store.terra_track_events('Column inserted');
        }
        if (key === 'rename')
          await saveChanges({ all_rows: true });

        await sleep(200);
        updateTableSettings({ clear_filter_condition: true });
        edit_field_popup.close();
      },
    },
  });
  edit_field_popup.open();
}
async function handleColumnOperations(key, action) {
  try {
    const payload = { op: action, projects: [], features: [], extraProperties: [{ key, value: null }] };
    table_data.value.forEach((properties) => {
      const f = terra_store.features_hash[properties.uid];
      const project = f?.properties?.project;
      if (project && !payload.projects.includes(project))
        payload.projects.push(project);
      payload.features.push(properties.uid);
    });
    const res = await $services.features.post({
      url: `features/container/${terra_store.container.uid}/properties/operations`,
      body: payload,
    });
    const response = {};
    (res.data || []).forEach((el) => {
      response[el.uid] = el;
    });
    updateFeaturesInState(payload.features.map(uid => ({ uid })), response);
  }
  catch (error) {
    logger.log(error);
  }
}
async function saveChanges(options = {}) {
  state.is_saving = true;
  try {
    let payload = [];
    if (options.all_rows) {
      payload = table_data.value.map(item => removeEmptyKeys(omitBy(item, (value, key) => ['selected', 'project', 'Fly to', 'Feature type', 'Layer', 'Sublayer'].includes(key) || key.startsWith('smProperties')))).filter(row => Object.keys(row).length);
    }
    else {
      Object.keys(state.affected_rows).forEach((row_index) => {
        const row_data = getSourceDataAtRow(table_instance.value, row_index);
        payload.push(removeEmptyKeys(omitBy(state.data[row_data.uid], (value, key) => ['selected', 'project', 'Fly to', 'Feature type', 'Layer', 'Sublayer'].includes(key) || key.startsWith('smProperties'))));
      });
    }
    const res = await $services.terra.getExtraPropsDataSave({
      container_id: terra_store.container.uid,
      query: { json: true },
      body: payload,
    });
    const response = {};
    (res.data || []).forEach((el) => {
      response[el.uid] = el;
    });
    updateFeaturesInState(payload, response);
    terra_store.terra_track_events('Table data updated');
    state.affected_rows = {};
    state.show_save_changes = false;
  }
  catch (error) {
    logger.error(error);
  }
  state.is_saving = false;
}

function updateFeaturesInState(payload, response) {
  const project_feature_type_index = {};
  (payload || []).forEach((row) => {
    const f = terra_store.features_hash[row.uid];
    if (response[f?.properties?.uid]) {
      f.properties.name = response[f.properties.uid].name;
      f.properties.description = response[f.properties.uid].description;
      f.properties.extraProperties = response[f.properties.uid].extraProperties;

      const project_id = f.properties.project || f.properties.projectUid;
      const feature_type_id = f.properties.featureTypeId;

      const project_feature_type_features = terra_store.projects_features_map[project_id]?.[feature_type_id] || [];

      if (!project_feature_type_index[project_id])
        project_feature_type_index[project_id] = {};
      if (!project_feature_type_index[project_id][feature_type_id]) {
        project_feature_type_index[project_id][feature_type_id] = project_feature_type_features.reduce((acc, curr, i) => {
          acc[curr.properties.uid] = i;
          return acc;
        }, {});
      }

      const featureIndex = project_feature_type_index[project_id][feature_type_id]?.[f.properties.uid] || -1;
      if (featureIndex !== -1) {
        project_feature_type_features[featureIndex] = f;
        terra_store.projects_features_map[project_id][feature_type_id] = project_feature_type_features;
      }
    }
  }, []);
  terra_store.update_features_on_map_flag += 1; // trigger watcher in terra filter
}
async function onSearchInput(e) {
  state.show_filter_loader = true;
  updateSearch(e);
  await terra_store.map.once('idle');
  terra_store.terra_track_events('Table searched');
  state.show_filter_loader = false;
  emit('saveView');
}
function updateFilterSortState(column_action, options) {
  if (column_action === 'add') {
    const updateColumnsIndex = (data) => {
      if (data.column >= options.selected_column_index + (options.side.includes('left') ? 0 : 1))
        data.column += 1;
      return data;
    };
    // Update filter and sort column index
    terra_store.table_state.filters = terra_store.table_state.filters.map(updateColumnsIndex);
    terra_store.table_state.sort = terra_store.table_state.sort.map(updateColumnsIndex);
  }
  else if (column_action === 'delete') {
    const updateColumnsIndex = (acc, data) => {
      if (data.column !== options.selected_column_index) {
        data.column = data.column + ((data.column > options.selected_column_index) ? -1 : 0);
        acc.push(data);
      }
      return acc;
    };
    // Delete filter and sort column index
    terra_store.table_state.filters = terra_store.table_state.filters.reduce(updateColumnsIndex, []);
    terra_store.table_state.sort = terra_store.table_state.sort.reduce(updateColumnsIndex, []);
  }
  else if (column_action === 'reorder') {
    const updateColumnsIndex = (acc, curr) => {
      const column = table_instance.value.getColHeader(curr.column);
      const column_index = terra_store.table_state.ordered_keys_map[column];
      if (column_index) {
        curr.column = Object.keys(state.order).length + column_index - 1;
        acc.push(curr);
      }
      return acc;
    };
    // Update column in filter and remove filter for hidden columns
    terra_store.table_state.filters = terra_store.table_state.filters.reduce(updateColumnsIndex, []);
    terra_store.table_state.sort = terra_store.table_state.sort.reduce(updateColumnsIndex, []);
  }
}
function attachmentRenderer(instance, td, row, col, prop, value, cellProperties) {
  let htmlString = '';
  if (value && Array.isArray(value)) {
    value?.forEach((item) => {
      const url = item?.url || item?.service?.url;
      htmlString += `<div><a href=${url} target="_blank">${item.name || 'Attachment'}</a></div>`;
      td.innerHTML = htmlString;
      td.className = 'htLeft htMiddle htDimmed';
    });
  }
  if (cellProperties.readOnly)
    td.className += ' read-only-cell';
}
function smPropertiesRenderer(instance, td, row, col, prop, value, cellProperties) {
  td.innerHTML = value;
  td.className = 'htLeft htMiddle htDimmed';
  if (cellProperties.readOnly)
    td.className += ' read-only-cell';
}
function updateColumnsOrder(update_table = true) {
  try {
    state.columns = state.columns.slice(0, 2);
    const first_index_in_data_array = Object.values(state.data)?.[0] || {};
    state.columns = Object.keys(first_index_in_data_array).filter(key => key !== 'selected').reduce((acc, cur) => {
      if (cur.startsWith('smProperties_')) {
        const field_name = cur.replace('smProperties_', '');
        const { all_fields_map } = terra_store.sm_instances_map;
        const field_data = all_fields_map[field_name];
        if (field_data?.type === 'file') {
          acc.push({
            data: `smProperties_${field_name}`,
            type: 'text',
            renderer: 'attachment-renderer',
            readOnly: true,
            className: 'htLeft htMiddle',
          });
        }
        else {
          acc.push({
            data: `smProperties_${field_name}`,
            type: 'text',
            readOnly: true,
            className: 'htLeft htMiddle',
            renderer: 'sm-properties-renderer',
          });
        }
      }
      else if (!['selected', 'project', 'Fly to'].includes(cur)) {
        acc.push({
          data: cur,
          type: 'text',
          readOnly: ['uid', 'Feature type', 'Layer', 'Sublayer'].includes(cur) || !can_modify_feature.value,
          hidden: cur === 'uid',
          className: 'htLeft htMiddle',
        });
      }
      return acc;
    }, [...state.columns]);

    state.columns = state.columns.filter(column => Object.keys(state.order).includes(column.data) || (!Object.keys(terra_store.table_state.ordered_keys_map).length || terra_store.table_state.ordered_keys_map[column.data]));

    state.columns = sortBy(state.columns, (column) => {
      if (state.order[column.data])
        return state.order[column.data];
      else if (terra_store.table_state.ordered_keys_map[column.data])
        return Object.keys(state.order).length + terra_store.table_state.ordered_keys_map[column.data];
      else
        return Number.MAX_SAFE_INTEGER;
    });
    if (update_table && table_instance.value)
      updateTableSettings();
  }
  catch (err) {
    logger.log(err);
  }
}
function sortData(update_table = true, sorting_type = 'Horizontal', sorting_order = 'asc') {
  const sorted_data = Object.keys(state.data)
    .sort((a, b) => {
      const index_to_use = sorting_type === 'Horizontal' ? 0 : 1;
      if (sorting_order === 'asc')
        return state.centroid_map[a][index_to_use] - state.centroid_map[b][index_to_use];
      return state.centroid_map[b][index_to_use] - state.centroid_map[a][index_to_use];
    })
    .reduce((acc, uid) => {
      acc[uid] = state.data[uid];
      return acc;
    }, {});
  state.data = sorted_data;
  if (update_table && table_instance.value)
    table_instance.value.updateSettings({ data: table_data.value });
}
function applyFilters(options = {}) {
  try {
    const filters_plugin = table_instance.value.getPlugin('filters');
    if (options.clear_filter_condition)
      filters_plugin.clearConditions();

    if (terra_store.table_state.filters.length || terra_store.table_state.search) {
      terra_store.table_state.filters.forEach(({ column, conditions, operation }) => {
        conditions.forEach(({ args, name }) => {
          filters_plugin.addCondition(column, name, args, operation);
        });
      });
      filters_plugin.filter();
    }
    else if (options.clear_filter_condition) {
      filters_plugin.filter();
    }
  }
  catch (error) {
    logger.error(error);
  }
}
function removeFilters() {
  terra_store.update_map_features_and_polygon({ features: terra_store.features_on_map });
  terra_store.table_state.features = cloneDeep(terra_store.features_on_map);
}
function expandTable() {
  state.expanded = !state.expanded;
  state.container_height = state.expanded ? 'calc(100vh - 64px)' : '60%';
  destroyAndReconstructTable();
  terra_store.terra_track_events(`Table ${state.expanded ? 'maximized' : 'minimized'}`);
}
async function onColumnsOrdered() {
  state.show_filter_loader = true;
  updateFilterSortState('reorder');
  updateColumnsOrder(false);
  await sleep(500);
  updateTableSettings({ clear_filter_condition: true });
  emit('saveView');
  state.show_filter_loader = false;
}
onMounted(async () => {
  turf = await import('@turf/turf');
  terra_store.terra_track_events('Table opened');
  try {
    state.is_loading = true;
    await load_js_css_file(
      'https://cdn.jsdelivr.net/npm/handsontable@13.1.0/dist/handsontable.full.min.js',
      'handsontable-js',
      'js',
    );
    await load_js_css_file(
      'https://cdn.jsdelivr.net/npm/handsontable@13.1.0/dist/handsontable.full.min.css',
      'handsontable-css',
      'css',
    );
    await load_js_css_file(
      'https://cdnjs.cloudflare.com/ajax/libs/PapaParse/5.4.1/papaparse.min.js',
      'papaparser-js',
      'js',
    );
    await getFeaturesData();
    loadHandsOn();
    state.table_initialized = true;
    updateTableSettings();
    state.is_loading = false;
  }
  catch (err) {
    state.is_loading = false;
    logger.error(err);
  }
});
onBeforeUnmount(() => {
  removeFilters();
  Handsontable.hooks.destroy(table_instance.value);
  terra_store.terra_track_events('Table closed');
  terra_store.table_state.features = null;
});
</script>

<template>
  <div class="fixed bottom-0 border border-gray-200  z-[998] bg-white transition-all ease-in-out duration-300" :class="[props.active_menu ? 'left-[380px] w-[calc(100vw-380px)]' : 'left-[80px] w-[calc(100vw-80px)]']" :style="{ height: state.container_height }">
    <div v-if="state.is_loading || terra_store.is_loading" class="absolute h-full w-full bg-gray-100 opacity-90 z-[181] flex justify-center items-center">
      <HawkLoader />
    </div>
    <TableHeader
      :table_instance="table_instance"
      :table_data="table_data"
      :table_options="state"
      :sort-data="sortData"
      :save-changes="saveChanges"
      :can_modify_feature="can_modify_feature"
      @update-data="state.data = $event"
      @update-columns-order="updateColumnsOrder($event)"
      @on-search="onSearchInput"
      @on-columns-ordered="onColumnsOrdered"
      @expand-table="expandTable"
      @open-pivot-table="emit('openPivotTable', table_data)"
      @open-creation-popup="emit('openCreationPopup', $event)"
      @close="emit('close')"
      @update-table-settings="updateTableSettings($event)"
    />
    <div class="h-[calc(100%-62px)]">
      <div id="bulk-edit-table" />
    </div>
  </div>
</template>

<style lang="scss">
.handsontable {
  &.lightblue-bg {
    background-color: lightblue;
  }
  input {
    appearance: auto;
  }
  .htFiltersMenuLabel {
    font-size: 13px !important;
  }
  .htFiltersMenuCondition .htUIInput input,
  .htFiltersMenuValue .htUIMultipleSelectSearch input  {
    height: 24px;
  }
  .htUIMultipleSelectHot table.htCore {
    margin: 0px !important;
  }
}
.htDropdownMenu table.htCore {
  margin-top: -60px !important;
  margin-left: 22px !important;
}
</style>

<style lang="scss" scoped>
  .handsontable .lightblue-bg {
    background: lightblue !important;
  }
</style>

<style lang="scss" scoped>
:deep(.handsontable) {
  TH {
    border-bottom: none !important;
    border-right: none !important;
    border-left: none !important;
  }
  TH:nth-child(1){
    border-left: 1px solid #EAECF0 !important;
  }
  TD{
    padding: 0px 8px;
    border-right: none !important;
  }
}
:deep(.htCore) {
  th {
    vertical-align: middle;
    padding: 4px;
    margin: 0px !important;
    font-weight: 500;
    text-align: left;
    background-color: rgba(249, 250, 251, 1);
    &:nth-child(1) {
      text-align: center;
    }
  }
  td {
    cursor: pointer;
    max-width: 30rem;
  }
}
</style>
