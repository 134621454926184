<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { compact, filter, groupBy, has, isArray, keyBy, max, mean, min, orderBy, sortBy, sum, transform } from 'lodash-es';
import { computed, onMounted } from 'vue';
import { useModal } from 'vue-final-modal';
import AssetHierarchySettings from '~/assets/components/assets-hierarchy-settings.vue';
import HawkCustomFieldValueRender from '~/common/components/molecules/hawk-custom-field-value-render/hawk-custom-field-value-render.vue';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useViewConfiguration } from '~/common/composables/useViewConfiguration.js';

const props = defineProps({
  assets: {
    type: Array,
  },
  hawk_menu_items: {
    type: Array,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  search: {
    type: String,
    default: '',
  },
  column_options: {
    type: Array,
    default: () => [],
  },
});

const { $t, $date, auth_store, common_store, route } = useCommonImports();

const { getHierarchyData, getTotal, getAssetCount } = useHierarchyData();

const forceUpdate = ref(0);
const table_instance = ref(null);

const sorting_state = ref({ id: 'name', desc: false });
const custom_view_configuration = {
  service: 'core',
  feature: 'list_view',
  resource_type: 'asset',
  name: auth_store?.current_organization?.uid,
  store_key: `asset_list`,
  modify_permissions: auth_store.check_permission('modify_assets', route.params.asset_id),
};
const { groups, setView, active_view_columns_map, updateView } = useViewConfiguration(custom_view_configuration);

const sorted_asset_list = computed(() => {
  return getHierarchyData(orderBy(props?.assets, (item) => {
    return has(item, sorting_state.value?.id) ? item[sorting_state.value?.id]?.toLowerCase() : item.metadata[sorting_state.value?.id]?.value;
  }, [sorting_state.value?.desc ? 'desc' : 'asc']), groups.value);
});

const filtered_column_options = computed(() => {
  if (groups.value?.length)
    return props.column_options.filter(column => !groups.value.includes(column.id));
  return props.column_options;
});

const pagination_config = computed({
  get() {
    return { totalRows: props.assets.length, pageSize: 25 };
  },
  set: (newValue) => {
    pagination_config.value.pageIndex = newValue.pageIndex;
  },
});

const metadata_fields = computed(() => {
  return filter(props.column_options, c => !['name', 'code', 'address', 'context_menu'].includes(c.id))
    .map((item) => {
      return {
        ...item,
        ...(common_store.assets_custom_fields_map[item.accessorKey] || {}),
      };
    });
});
function getGroupOptions() {
  const possible_fields = new Set(['text', 'number', 'dropdown', 'checkbox', 'formula', 'radio', 'date', 'members']);

  const options = common_store?.assets_custom_fields
    .filter(item => possible_fields.has(item.type) && !active_view_columns_map.value[item.uid])
    .map(item => ({ label: item.name, value: item.uid }));
  return [...options];
}

const { open: openHierarchySettings, close: closeHierarchySettings, patchOptions: patchHierarchyOptions } = useModal({
  component: AssetHierarchySettings,
});
function HierarchySettingsHandler() {
  patchHierarchyOptions(
    {
      attrs: {
        groups: groups.value,
        group_options: getGroupOptions(),
        onClose() {
          closeHierarchySettings();
        },
        update: async (data) => {
          await updateView(data);
          forceUpdate.value++;
          closeHierarchySettings();
        },
      },
    },
  );
  openHierarchySettings();
}

watch(() => props.search, () => {
  table_instance.value.resetPagination();
});

function useHierarchyData() {
  // Helper functions
  const multiGroupBy = (array, ...groupingFunctions) => {
    if (!groupingFunctions || groupingFunctions.length === 0)
      return array;

    const currGrouping = groupBy(array, groupingFunctions[0]);
    if (groupingFunctions.length === 1)
      return currGrouping;

    return transform(currGrouping, (result, value, key) => {
      result[key] = multiGroupBy(value, ...groupingFunctions.slice(1));
    }, {});
  };

  function toArrayformat(object, count = 0) {
    if (isArray(object))
      return object;

    return Object.keys(object).map((key) => {
      return {
        name: key || 'null',
        field_uid: groups.value[count],
        subRows: toArrayformat(object[key], count + 1),
      };
    });
  }

  const getFlatList = (data, list = []) => {
    if (isArray(data)) {
      data.forEach((item) => {
        if (item?.subRows)
          getFlatList(item.subRows, list);
        else
          list.push(item);
      });
    }

    return list;
  };

  function getAssetCount(data) {
    return getFlatList(data)?.length;
  }
  const getTotal = (data, cf) => {
    if (!cf.properties.aggregation || !['sum', 'min', 'max', 'avg', 'count'].includes(cf?.properties?.aggregation))
      return '';

    const list = getFlatList(data);
    const values = list.map(item => item?.metadata[cf.uid]?.value || 0);
    const total_count = values.length;

    if (cf.type === 'planned_actual') {
      const prefix = cf?.properties?.prefix ?? '';
      const suffix = cf?.properties?.suffix ?? '';
      const actual_values = list.map(item => item.metadata[cf.uid]?.value?.actual || 0);
      const planned_values = list.map(item => item.metadata[cf.uid]?.value?.planned || 0);
      let value_string = '';
      switch (cf.properties.aggregation) {
        case 'sum':
          value_string = `${prefix} ${formatNumber(sum(actual_values))} ${suffix}/${prefix} ${formatNumber(sum(planned_values))} ${suffix}`;
          break;
        case 'avg':
          value_string = `${prefix} ${formatNumber(mean(actual_values))} ${suffix}/${prefix} ${formatNumber(mean(planned_values))} ${suffix}`;
          break;
        case 'min':
          value_string = `${prefix} ${formatNumber(min(actual_values))} ${suffix}/${prefix} ${formatNumber(min(planned_values))} ${suffix}`;
          break;
        case 'max':
          value_string = `${prefix} ${formatNumber(max(actual_values))} ${suffix}/${prefix} ${formatNumber(max(planned_values))} ${suffix}`;
          break;
      }
      return value_string;
    }

    else if (cf.type === 'percentage') {
      if (cf.properties.aggregation === 'sum')
        return `${formatNumber((sum(values) / total_count) * 100)}%`;
      else if (cf.properties.aggregation === 'avg')
        return `${formatNumber(mean(values) * 100)}%`;
      else if (cf.properties.aggregation === 'min')
        return `${formatNumber(min(values) * 100)}%`;
      else if (cf.properties.aggregation === 'max')
        return `${formatNumber(max(values) * 100)}%`;
    }

    let formattedValue = null;

    switch (cf.properties.aggregation) {
      case 'sum':
        formattedValue = formatNumber(sum(values));
        break;
      case 'avg':
        formattedValue = formatNumber(mean(values));
        break;
      case 'min':
        formattedValue = formatNumber(min(values));
        break;
      case 'max':
        formattedValue = formatNumber(max(values));
        break;
    }
    return formattedValue;
  };

  function formatNumber(value) {
    return Number.isInteger(value) ? value : Number.parseFloat(value).toFixed(2);
  }

  function getHierarchyData(data, groupingKeys) {
    if (groups.value.length) {
      return sortBy(toArrayformat(
        multiGroupBy(
          data,
          ...groupingKeys.map(key => item => item?.metadata[key]?.value),
        ),
      ), 'name');
    }
    else {
      return toArrayformat(
        multiGroupBy(
          data,
          ...groupingKeys.map(key => item => item?.metadata[key]?.value),
        ),
      );
    }
  }

  return {
    getHierarchyData,
    getTotal,
    getAssetCount,
  };
}

function getAllFieldsAggregation() {
  return compact(props.column_options?.map((column) => {
    return common_store.assets_custom_fields_map[column?.accessorKey]
      ? common_store.assets_custom_fields_map[column?.accessorKey]?.properties?.aggregation
      : null;
  })) || [];
}

function footer_function(col_id) {
  if (col_id === 'name')
    return $t('Summary');

  const cf = metadata_fields.value.find(m => m.uid === col_id);
  if (cf?.properties?.aggregation)
    return getTotal(sorted_asset_list.value, cf);
  return '-';
}

function getConfigMap(field_uid) {
  return keyBy(common_store.assets_custom_fields_map?.[field_uid]?.config, 'uid');
}

onMounted(() => {
  setView({});
});
</script>

<template>
  <TableWrapperVue class="!mt-0">
    <HawkTable
      :key="forceUpdate"
      :custom_view_configuration="custom_view_configuration"
      :data="sorted_asset_list"
      :columns="filtered_column_options"
      :default_columns="['name', 'code', 'address']"
      :is_loading="loading"
      is_gapless
      :show_footer="!!getAllFieldsAggregation()?.length"
      :footer_function="footer_function"
      footer_type="column"
      cell_height="40px"
      freeze_column_id="name"
      :non_sortable_columns="['context_menu', 'address']"
      :manual_sorting="true"
      :header_context_menu_config="custom_view_configuration.modify_permissions ? {
        is_menu_fixed: true,
        column_settings: true,
        additional_items: [{
          label: 'Group by',
          uid: 'group-by',
          on_click: () => {
            HierarchySettingsHandler();
          },
        }],
      } : {}"
      is_context_menu_fixed
      show_columns_popup
      @sort="e => sorting_state = { id: e.sort_by?.id || 'name', desc: e.sort_by?.desc || false }"
      @table-instance="table_instance = $event"
    >
      <template
        v-for="custom_field in metadata_fields"
        #[custom_field.id]="cf"
        :key="custom_field.id"
      >
        <div v-if="custom_field?.properties?.aggregation && cf?.data?.row?.original?.subRows">
          {{ getTotal(cf?.data?.row?.original?.subRows, custom_field) }}
        </div>
        <HawkCustomFieldValueRender v-else :field_value="cf?.data?.row?.original?.metadata?.[custom_field?.accessorKey]" field_selector="field" />
      </template>
      <template #name="uid">
        <div v-if="['null', 'undefined'].includes(uid.data.row.original.name)" class="font-semibold">
          {{ $t('Unknown') }}
        </div>
        <div v-else-if="['checkbox', 'dropdown', 'radio'].includes(common_store.assets_custom_fields_map?.[uid.data.row.original?.field_uid]?.type)">
          <div v-for="(item, index) in uid.data.getValue()?.split(',')" :key="item.id" class="inline-flex font-semibold">
            {{ getConfigMap(uid.data.row.original.field_uid)?.[item]?.name }}
            <span v-if="index !== (uid.data.getValue()?.split(',')?.length - 1)">, </span>
          </div>
        </div>
        <div v-else-if="['members'].includes(common_store.assets_custom_fields_map?.[uid.data.row.original?.field_uid]?.type)">
          <HawkMembers :members="uid.data.getValue()?.split(',')" type="badge" class="flex flex-wrap" />
        </div>
        <div
          v-else
          class="font-semibold"
          @click="!uid.data.row.original.field_uid?.length
            ? $router.push({
              name: 'asset-dashboard',
              params: { asset_id: uid.data.row.original.uid },
            }) : null
          "
        >
          {{ ['null', 'undefined'].includes(uid.data.row.original.name) ? 'Unknown' : uid.data.row.original.name }}
        </div>
        <span v-if="getAssetCount(uid.data.row?.original?.subRows)" class="ml-1 text-[12px] text-gray-600 font-normal">
          ({{ getAssetCount(uid.data.row?.original?.subRows) }})
        </span>
      </template>
      <template #created_by="created_by">
        <HawkMembers :members="created_by?.data.getValue()" type="label" :has_avatar="false" />
      </template>
      <template #address="address">
        {{ address.data.getValue()?.name || '-' }}
      </template>
      <template #code="code">
        {{ code.data.row.original?.code ? `#${code.data.row.original?.code}` : '-' }}
      </template>
      <template #created_at="created_at">
        {{ $date(created_at.data.getValue(), "DATE_SHORT") }}
      </template>
      <template #context_menu="asset">
        <HawkMenu
          v-if="auth_store.check_permission('modify_assets', asset.uid)" :items="hawk_menu_items" position="fixed"
          @select="(e) => e.onClick(asset.data.row.original)"
        >
          <template #trigger>
            <IconHawkDotsVertical />
          </template>
        </HawkMenu>
      </template>
    </HawkTable>
  </TableWrapperVue>
</template>
