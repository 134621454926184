<script setup>
// --------------------------------- Imports -------------------------------- //
import { debounce } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import FormStatus from '~/forms/atoms/form-status.vue';
import FormBulkUpdateDropdown from '~/forms/components/form-bulk-update-dropdown.vue';
import FormsSearchAndFilter from '~/forms/components/forms-search-and-filter.vue';
import { useExportPDF } from '~/forms/composables/form-detail-composable';
import { FORM_PERMISSIONS } from '~/forms/constants';
import { useFormsStore } from '~/forms/store/forms.store';

// ---------------------------------- Props --------------------------------- //

// ---------------------------------- Emits --------------------------------- //

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //
const forms_store = useFormsStore('archived_forms');

// --------------------- Variables - Pinia - storeToRefs -------------------- //
const { formsPDFExportHandler } = useExportPDF();

// ------------------- Variables - Local - consts and lets ------------------ //
const { $t, $date, $services, $toast, auth_store, common_store, route, router } = useCommonImports();

const columns = computed(() => [
  {
    accessorKey: 'select',
    header: '',
    id: 'select',
    size: 100,
    enableSorting: false,
    enableResizing: false,
    show_on_hover: 'true',
  },
  {
    header: $t('Form name'),
    accessorKey: 'name',
    id: 'name',
    size: 200,
    enableResizing: false,
  },
  {
    header: $t('Category'),
    accessorKey: 'category',
    id: 'category',
    size: 200,
    enableResizing: false,
  },
  {
    accessorKey: 'assignees',
    header: $t('Assignees'),
    id: 'assignees',
    size: 100,
    enableResizing: false,
    enableSorting: false,
  },
  {
    header: $t('Due date'),
    accessorKey: 'due_date',
    id: 'due_date',
    size: 100,
    enableResizing: false,
  },
  {
    header: $t('Status'),
    accessorKey: 'status',
    id: 'status',
    enableResizing: false,
    enableSorting: false,
  },
]);

// ------------------------ Variables - Local - refs ------------------------ //
const loading = ref(false);
const is_pdf_exporting = ref(false);
const selected_forms = ref([]);
const available_forms = ref([]);
const search = ref('');
const applied_filters = ref(null);
const total_archived_form_count = ref(0);

// ---------------------- Variables - Local - reactives --------------------- //
const table_state = reactive({
  page_size: 25,
  page_number: 1,
  table_loading: false,
  table_instance: null,
});

// --------------------------- Computed properties -------------------------- //
const has_data = computed(() => !!available_forms.value.length);
const has_filter_applied = computed(() => applied_filters.value || search.value);
const is_boarding = computed(() => !has_data.value && !has_filter_applied.value);
const has_no_results = computed(() => !has_data.value && has_filter_applied.value);

const action_options = computed(() => [
  ...(auth_store.check_permission(FORM_PERMISSIONS.V2_MODIFY_FORMS, route.params.asset_id)
    ? [
        { uid: 1, label: $t('Delete'), on_click: deletePopup },
      ]
    : []),
  ...(auth_store.check_split('forms_export_pdf')
    ? [
        { uid: 2, label: $t('Export PDF'), on_click: exportCTAHandler, disabled: selected_forms.value.length > 100, tooltip: selected_forms.value.length > 100 ? $t('Can not export more than 100 forms') : '' },
      ]
    : []
  ),
  ...(auth_store.check_permission(FORM_PERMISSIONS.V2_MODIFY_FORMS, route.params.asset_id)
    ? [
        {
          uid: 4,
          label: $t('Unarchive'),
          on_click: () => {
            forms_store.unarchiveForms(selected_forms.value.map(form => form.uid), (data) => {
              const { unarchived, errors } = data.forms;

              if (!unarchived.length && errors.length) {
                $toast({
                  title: $t('Unable to unarchive'),
                  type: 'error',
                  position: 'bottom-right',
                  text: `${$t(errors.length > 1 ? 'Forms' : 'Form')} ${$t('could not be archived, please check submission status and block type')}`,
                });
              }

              if (unarchived.length === selected_forms.value.length) {
                $toast({
                  title: `${$t(unarchived.length > 1 ? 'Forms' : 'Form')} ${$t('unarchived')}`,
                  type: 'success',
                  position: 'bottom-right',
                  text: `${$t(unarchived.length > 1 ? 'Forms' : 'Form')} ${$t('has been successfully unarchived.')}`,
                });
              }

              if (unarchived.length && errors.length) {
                $toast({
                  title: `${unarchived.length}/${selected_forms.value.length} ${$t('forms have been unarchived')}`,
                  type: 'warning',
                  position: 'bottom-right',
                  timeout: false,
                  has_dismiss_button: true,
                  dismiss_button_text: $t('Ok, got it'),
                  text: `${$t('Only')} ${unarchived.length}/${selected_forms.value.length} ${$t('has been successfully unarchived')}`,
                });
              }

              available_forms.value = available_forms.value.filter(form => !unarchived.includes(form.uid));
              total_archived_form_count.value = total_archived_form_count.value - unarchived.length;
              clearBulkSelection();
            });
          },
        },
      ]
    : []),
]);

// -------------------------------- Functions ------------------------------- //
async function getData(options = {}) {
  const { extra_filters } = options;

  table_state.table_loading = true;
  if (options?.pagination_state?.pageIndex && options?.pagination_state?.pageSize) {
    table_state.page_number = options?.pagination_state.pageIndex || table_state.page_number;
    table_state.page_size = options?.pagination_state.pageSize || table_state.page_size;
  }

  const { data, headers } = await $services.forms.post({
    attribute: 'list-view',
    body: {
      filters: {
        archived: true,
        include_template_name: true,
        templates: [],
        page_number: table_state.page_number,
        page_size: table_state.page_size,
        ...(options?.sort_by
          ? { sort: options.sort_by.desc ? `-${options.sort_by.id}` : options.sort_by.id }
          : {}),
        ...(search?.value ? { q: search.value } : {}),
        ...(
          applied_filters.value
            ? {
                advanced_filters: { logic: { type: 'AND' }, rules: applied_filters.value.filters },
                [applied_filters.value.user_filter.value]: !!applied_filters.value.user_filter.value,
              }
            : {}
        ),
        ...extra_filters,
      },
    },
  });

  available_forms.value = data.forms;
  total_archived_form_count.value = headers['x-total-count'];
  table_state.table_loading = false;
}

function clearBulkSelection() {
  table_state.table_instance.clearSelect();
}

function handleFormSelection(e) {
  selected_forms.value = e.map(forms_list_row => forms_list_row.original);
}

function openFormSubmissionComponent(row) {
  router.push({ query: { form: btoa(JSON.stringify({ form_uid: row.uid, store_key: 'archived_forms', archived: true })) } });
}

function exportCTAHandler() {
  if (is_pdf_exporting.value)
    is_pdf_exporting.value = false;
  nextTick(() => {
    is_pdf_exporting.value = true;
  });
}

function get_location_name(form) {
  let location_name = [];

  if (form?.target_element?.asset && common_store.get_asset(form.target_element.asset)) {
    if (form.target_element.asset) {
      location_name = [
        common_store.get_asset(form.target_element.asset).name,
      ];
    }
    if (form.properties?.reference_name)
      location_name.push(form.properties.reference_name);
  }

  if (form.template?.name)
    location_name.push(form.template.name);

  return location_name.length ? location_name.filter(name => !!name).join(' > ') : null;
}

async function formBulkDeleteHandler() {
  const selected_forms_uids = selected_forms.value.map(form => form.uid);
  await forms_store.remove_forms(selected_forms_uids);
  clearBulkSelection();
}

function deletePopup() {
  const form_text = selected_forms.value.length > 1 ? $t('forms') : $t('form');
  const content = `${$t('Are you sure you want to delete')} ${selected_forms.value.length} ${form_text} ? ${$t('This action cannot be undone.')}`;

  const { open: openDeletePopup, close: closeDeletePopup } = useModal({
    component: HawkDeletePopup,
    attrs: {
      header: $t('Delete Forms'),
      content,
      onClose() {
        closeDeletePopup();
      },
      confirm: async () => {
        try {
          await formBulkDeleteHandler();
          closeDeletePopup();
        }
        catch (err) {
          logger.log(err);

          $toast({
            title: 'Something went wrong',
            text: 'Please try again',
            type: 'error',
          });
        }
      },
    },
  });
  openDeletePopup();
}

function onSearch(val) {
  search.value = val;
}

async function onFilterApply({ filters, user_filter }) {
  applied_filters.value = { filters, user_filter };
  table_state.page_number = 1;

  await getData({ });
}

const debouncedGetData = debounce(getData, 500);

forms_store.$onAction(async ({ _args, name, after }) => {
  after(() => {
    if (['unarchiveForms', 'remove_forms'].includes(name))
      getData({});
  });
});

// -------------------------------- Watchers -------------------------------- //
watch(() => search?.value?.length, () => {
  // when search value is present reset the pagination
  table_state.page_number = 1;
  debouncedGetData();
});
// ----------------------------- Lifecycle Hooks ---------------------------- //
onMounted(async () => {
  loading.value = true;
  await getData({});
  loading.value = false;
});
</script>

<template>
  <HawkPageHeader :title="$t('Archived forms')" class="-mx-4" />
  <div>
    <div class="mb-4">
      <FormBulkUpdateDropdown v-if="selected_forms.length" :selected_items="selected_forms" :bulk_action_options="action_options" @clear="clearBulkSelection()" />
      <div v-else>
        <FormsSearchAndFilter :quick_filters="['templates', 'priority', 'category', 'assignees', 'due_date']" @search="onSearch" @apply="onFilterApply" />
      </div>
    </div>
    <div v-if="!has_data && (loading || table_state.table_loading)">
      <hawk-loader />
    </div>
    <HawkIllustrations v-else-if="has_no_results" type="no-results" for="forms" />
    <HawkIllustrations v-else-if="!has_data" variant="default" type="no-data" for="forms" />
    <HawkIllustrations v-else-if="is_boarding" type="on-boarding" for="forms" />
    <TableWrapperVue v-else class="max-w-[calc(100vw-80px)] !max-h-[calc(100vh-65px)]" container_class="mt-0">
      <HawkTable
        :key="table_state.page_number" :is_loading="table_state.table_loading"
        :pagination_config="{ totalRows: total_archived_form_count, pageIndex: table_state.page_number, pageSize: table_state.page_size }"
        :non_sortable_columns="['context_menu']" :data="available_forms" :columns="columns" :is_gapless="true"
        :disable_resize="true" :show_menu_header="false" :manual_pagination="true"
        :manual_sorting="true" @pagination="getData($event)" @sort="getData($event)"
        @table-instance="table_state.table_instance = $event" @row-clicked="openFormSubmissionComponent"
        @select-row="handleFormSelection"
      >
        <template #group-label="{ data }">
          <div class="text-sm font-semibold">
            <span class="capitalize mr-1">{{ data.value }}</span> ({{ data.count }} {{ $t('forms') }})
          </div>
        </template>
        <template #name="form">
          <div class="whitespace-nowrap">
            <hawk-text :length="60" class="text-xs text-gray-600 mb-0.5 block" :content="get_location_name(form.data.row.original)" />
            <hawk-text :length="60" class="text-gray-900 font-medium" :content="form.data.getValue()" />
          </div>
        </template>
        <template #assignees="assignees">
          <HawkMembers v-if="assignees.data.getValue()?.length" :members="assignees.data.getValue()" type="badge" />
          <span v-else>-</span>
        </template>
        <template #category="category">
          <HawkCategoryName v-if="category.data.getValue()" :uid="category.data.getValue()" />
          <p v-else>
            -
          </p>
        </template>
        <template #due_date="due_date">
          <span
            class="text-sm whitespace-nowrap"
            :class="[due_date.data.getValue() && new Date(due_date.data.getValue()) <= new Date() ? 'text-[#B42318]' : 'text-[#475467]']"
          >
            {{ due_date.data.getValue() ? $date(due_date.data.getValue(), 'DD MMMM YYYY') : '-' }}
          </span>
        </template>
        <template #status="status">
          <FormStatus :form="status.data.row.original" />
        </template>
        <template #context_menu="form">
          <slot name="context_menu" :element="form" />
        </template>
      </HawkTable>
    </TableWrapperVue>
  </div>
  <HawkExportToast
    v-if="is_pdf_exporting"
    v-bind="{
      ...(selected_forms.length > 1 ? {
        progress_text: $t('Exporting PDF'),
        completed_text: $t('Export request received'),
        completed_subtitle: $t('You will receive an email to download once the export is completed.'),
      } : {}),
    }"
    :submit="() => formsPDFExportHandler(selected_forms, route.params?.template_uid, () => is_pdf_exporting = false, forms_store)"
    @cancel="is_pdf_exporting = false"
    @close="is_pdf_exporting = false"
  />
</template>
