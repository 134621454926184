<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { omit } from 'lodash-es';
import { computed, onMounted } from 'vue';
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import CustomViewForm from '~/common/components/organisms/custom-views/custom-view-form.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useCustomViewStore } from '~/common/stores/custom-view.store.js';

const props = defineProps({
  payload: {
    type: Object,
    default: () => {},
  },
  selected_view: {
    type: String,
    default: null,
  },
  show_default: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['selectedView', 'deletedView', 'closeSaveView', 'updatedView', 'createdView']);

const custom_view_store = useCustomViewStore();
const route = useRoute();
const router = useRouter();
const auth_store = useAuthStore();
const $t = inject('$t');

const form$ = ref(null);

const filtered_views = computed(() => (custom_view_store.views || [])?.filter(view =>
  (!props.payload.service || view.service === props.payload.service)
  && (!props.payload.feature || view.feature === props.payload.feature)
  && (!props.payload.resource_type || view.resource_type === props.payload.resource_type)
  && (!props.payload.resource_id || view.resource_id === props.payload.resource_id)),
);
const view_items = computed(() => [...(props.show_default ? [{ value: 'default', label: $t('Default') }] : []), ...(filtered_views.value?.map(view => ({ value: view.uid, label: view.name })) || [])]);

onMounted(async () => {
  await custom_view_store.set_custom_views({ query: props.payload }, { force_update: true });
  if (props.selected_view)
    form$.value.el$('custom_view').load(props.selected_view);
});

const custom_view_popup = useModal({
  component: CustomViewForm,
});

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});

function openViewPopup() {
  custom_view_popup.patchOptions({
    attrs: {
      onClose() {
        custom_view_popup.close();
      },
      on_submit: async (data) => {
        try {
          const payload = {
            ...props.payload,
            ...data,
            organization: auth_store.current_organization?.uid,
            asset: route.params.asset_id,
          };
          const view = await custom_view_store.create_view(payload);
          emit('createdView');
          if (view?.uid && props.payload.service === 'terra') {
            router.replace({ query: { view_uid: view.uid } });
            form$.value.el$('custom_view').load(view.uid);
            emit('closeSaveView');
          }
        }
        catch (err) {
          logger.error(err);
        }
      },
    },
  });
  custom_view_popup.open();
}
function editViewPopup(view_uid) {
  const custom_view = custom_view_store.views.find(view => view.uid === view_uid);
  custom_view_popup.patchOptions({
    attrs: {
      onClose() {
        custom_view_popup.close();
      },
      onClosed() {
        const attrs = omit(custom_view_popup.options.attrs, ['custom_view']); // reset attrs
        custom_view_popup.options.attrs = attrs;
      },
      custom_view,
      on_submit: async (data) => {
        await custom_view_store.update_view(
          {
            ...props.payload,
            ...data,
            organization: auth_store.current_organization?.uid,
            asset: route.params.asset_id,

          },
          view_uid,
        );
        emit('updatedView');
      },
    },
  });
  custom_view_popup.open();
}
function deleteView(view) {
  patchOptions({
    attrs: {
      header: $t('Delete View'),
      content: `${$t('Are you sure you want to delete the view')} ${view.label}?`,
      onClose() {
        closeDeletePopup();
      },
      confirm: async () => {
        await custom_view_store.delete_view(view.value);
        emit('deletedView', view.value);
        closeDeletePopup();
      },
    },
  });
  openDeletePopup();
}
async function updateCustomView(data) {
  const custom_view = custom_view_store.views.find(view => view.uid === data.uid);
  await custom_view_store.update_view(
    {
      ...custom_view,
      ...data.payload,
      organization: auth_store.current_organization?.uid,
      asset: route.params.asset_id,
    },
    data.uid,
  );
};

onMounted(() => {
  emit('customViewsInstance', {
    openViewPopup,
    updateCustomView,
  });
});

function selectViewHandler(option) {
  emit('selectedView', option);
}
</script>

<template>
  <div class="absolute left-12">
    <Vueform
      ref="form$"
      size="sm"
      :float-placeholders="false"
      :display-errors="false"
    >
      <SelectElement
        name="custom_view"
        :native="false"
        :search="true"
        :can-clear="false"
        :items="view_items"
        :truncate="true"
        :can-deselect="false"
        :add-classes="{
          SelectElement: {
            select: {
              dropdown: ['scrollbar'],
            },
          },
        }"
        class="min-w-[14rem]"
        @select="selectViewHandler"
      >
        <template #before-list>
          <p
            class="flex items-center px-3 text-sm py-1.5 hover:bg-gray-100 font-medium"
            @click="openViewPopup"
          >
            <IconHawkPlus class="w-4 h-4 mr-1" /> {{ $t('Create view') }}
          </p>
        </template>
        <template #option="{ option }">
          <div class="flex justify-between w-full group">
            <span class="text-sm">{{ option.label }}</span>
            <div v-if="option.value !== 'default'" class="flex items-center gap-3 invisible group-hover:visible">
              <IconHawkEditOne class="w-3 h-3" @click.stop="editViewPopup(option.value)" />
              <IconHawkDeletePopup class="w-3 h-3" @click.stop="deleteView(option)" />
            </div>
          </div>
        </template>
      </SelectElement>
    </vueform>
  </div>
</template>
