/* eslint-disable no-undef */

import { load_js_css_file } from '~/common/utils/load-script.util';

export function useMap(options, emits = () => ({})) {
  const loadMapBoxPackage = async () => {
    await load_js_css_file(
      'https://api.mapbox.com/mapbox-gl-js/v2.13.0/mapbox-gl.css',
      'mapbox-gl-css',
      'css',
    );
    await load_js_css_file(
      'https://api.mapbox.com/mapbox-gl-js/v2.13.0/mapbox-gl.js',
      'mapbox-gl-js',
      'js',
    );
    await load_js_css_file(
      'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.0.9/mapbox-gl-draw.css',
      'mapbox-gl-draw-css',
      'css',
    );
    await load_js_css_file(
      'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.0.9/mapbox-gl-draw.js',
      'mapbox-gl-draw-js',
      'js',
    );
  };
  const loadMapboxCompare = async () => {
    await load_js_css_file(
      'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-compare/v0.1.0/mapbox-gl-compare.css',
      'mapbox-gl-compare-css',
      'css',
    );
    await load_js_css_file(
      'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-compare/v0.1.0/mapbox-gl-compare.js',
      'mapbox-gl-compare-js',
      'js',
    );
  };
  const loadTurf = async () => {
    await load_js_css_file(
      'https://cdn.jsdelivr.net/npm/@turf/turf@5/turf.min.js',
      'turf',
      'js',
    );
  };
  const onMapEvents = (event_data, event_name, map_id) => {
    return emits(event_data, event_name, map_id);
  };

  const addMapboxToken = async () => {
    window.mapboxToken = import.meta.env.VITE_APP_MAPBOX_TOKEN;
  };
  const initDraw = async (map_instance) => {
    const {
      SnapPolygonMode,
      SnapPointMode,
      SnapLineMode,
      SnapModeDrawStyles,
    } = await import('mapbox-gl-draw-snap-mode');

    const { DrawRectangleTool }
      = await import('~/common/utils/map-draw-modes.util.js');
    const {
      DirectMode,
      DragCircleMode,
      SimpleSelectMode,
    } = await import('mapbox-gl-draw-circle-mode');

    const modes = MapboxDraw.modes;

    modes.draw_rectangle = DrawRectangleTool;

    modes.draw_polygon = SnapPolygonMode;
    modes.draw_point = SnapPointMode;
    modes.draw_line_string = SnapLineMode;

    modes.drag_circle = DragCircleMode;
    modes.direct_select = DirectMode;
    modes.simple_select = SimpleSelectMode;

    const draw = new MapboxDraw(
      {
        keybindings: false,
        userProperties: true,
        displayControlsDefault: false,
        snap: false,
        boxSelect: false,
        styles: SnapModeDrawStyles,

        guides: false,
        controls: false,
        modes,
      },
    );

    map_instance.addControl(draw, 'top-left');
    return draw;
  };
  const initMapbox = async (options = {}) => {
    mapboxgl.accessToken = import.meta.env.VITE_APP_MAPBOX_TOKEN;
    const map = new mapboxgl.Map({
      container: options.container_id,
      style: options.style || 'mapbox://styles/mapbox/streets-v11', // set the map style here
      center: options.center || [0, 20], // set the initial map center here
      zoom: options.zoom || 1, // set the initial map zoom level here
      maxZoom: options.maxZoom || 24,
      attributionControl: false,
      pitchWithRotate: false, // Disable pitch
      dragRotate: false, // Disable rotate
    });
    map.on('load', () => {
      onMapEvents(null, 'loaded', options.container_id);
    });

    map.on('error', (error) => {
      onMapEvents(error, 'error');
    });
    // add event listeners here, for example:
    map.on('drag', (e) => {
      onMapEvents(e, 'dragging');
    });
    return map;
  };
  const removeMapboxInstance = (map) => {
    map?.remove?.();
  };
  const setSources = (sources, map_instance, generateId = false) => {
    (sources || []).forEach((item) => {
      if (!map_instance?.getSource(item)) {
        map_instance.addSource(item, {
          type: 'geojson',
          data: {
            features: [],
            type: 'FeatureCollection',
          },
          generateId,
        });
      }
    });
  };
  const removeSources = (sources, map_instance) => {
    (sources || []).forEach((item) => {
      if (map_instance.getSource(item))
        map_instance.removeSource(item);
    });
  };
  const removeLayers = (layers, map_instance) => {
    (layers || []).forEach((item) => {
      if (map_instance.getLayer(item))
        map_instance.removeLayer(item);
    });
  };
  const setLayers = (layers, map_instance) => {
    (layers || []).forEach((layer) => {
      if (!map_instance.getLayer(layer.id))
        map_instance.addLayer(layer);
    });
  };
  const resizeMap = (map_instance) => {
    if (map_instance)
      map_instance.resize();
  };

  const setMapCenter = (center, map_instance) => {
    if (map_instance)
      map_instance.setCenter(center);
  };

  const setMapZoom = (zoom, map_instance) => {
    if (map_instance)
      map_instance.setZoom(zoom);
  };

  const loadImages = (map, image_path = '', name = '', options = {}) => {
    map?.loadImage(image_path, (error, image) => {
      if (error)
        throw error;
      map?.addImage(name, image, options);
    });
  };

  return { resizeMap, setMapCenter, setMapZoom, loadMapBoxPackage, initMapbox, initDraw, setLayers, setSources, removeLayers, removeSources, addMapboxToken, onMapEvents, loadTurf, removeMapboxInstance, loadImages, loadMapboxCompare };
}
