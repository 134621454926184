<script setup>
const props = defineProps({
  form_uid: {
    type: String,
    required: true,
  },
  start_date: {
    type: String,
    required: true,
  },
  schedule: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['close', 'update']);

const hawk_schedule = ref(null);

const is_save_disabled = computed(() => hawk_schedule.value?.has_errors);

function save() {
  const data = hawk_schedule.value.setRecurringRule();
  delete data.schedule.create_new_ticket;
  emit('update', data);
  emit('close');
}
</script>

<template>
  <hawk-modal-container>
    <hawk-modal-header @close="emit('close')">
      <template #left>
        {{ $t('Set Schedule') }}
      </template>
    </hawk-modal-header>
    <hawk-modal-content>
      <HawkSchedule
        ref="hawk_schedule" module="form" :schedule="props.schedule"
        :start_date="start_date"
        :recur_when="{
          options: [
            { label: $t('On Schedule'), value: 'on_schedule' },
            { label: $t('Submitted'), value: 'on_submission' },
          ],
          default: 'on_schedule',
        }"
      />
    </hawk-modal-content>
    <hawk-modal-footer class="flex justify-between items-center">
      <template #right>
        <div class="flex justify-end items-center">
          <hawk-button
            class="mr-5"
            type="outlined"
            @click="$emit('close')"
          >
            {{ $t('Cancel') }}
          </hawk-button>
          <hawk-button
            color="primary"
            :loading="loading"
            :disabled="is_save_disabled"
            @click="save"
          >
            {{ $t('Save') }}
          </hawk-button>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
