import { keyBy, omit } from 'lodash-es';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

import { getDateInterval, isDateIntervalOperator } from '~/dashboard/components/filters/composables/filters-config';
import SelectOptionTemplate from '~/dashboard/components/vueform-schema-templates/select-option-template.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store';

export const useDashboardTasksStore = defineStore('dashboard_tasks', {
  state: () => ({
    tasks_configuration: {},
    type_options: [
      ['tasks_list', 'Tasks list', 'Shows a list of tasks matching the filters.'],
      ['vertical_graph', 'Vertical Bar Chart'],
      ['horizontal_bar', 'Horizontal Bar Chart'],
      ['donut', 'Pie Chart'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
        description: item[2],
      };
    }),
    field_options: keyBy([
      ['status', 'Status'],
      ['priority', 'Priority'],
      ['assignees', 'Assignees'],
      ['tags', 'Tags'],
      ['category', 'Category'],
      ['template', 'Template'],
      ['asset', 'Asset'],
      ['component_type', 'Component'],
      ['start_date', 'Start date'],
      ['due_date', 'Due date'],
      ['last_activity_at', 'Last activity'],
    ].map((item) => {
      return {
        key: item[0],
        label: item[1],
      };
    }), 'key'),
    breakdown_options: keyBy([
      ['none', 'None'],
      ['status', 'Status'],
      ['priority', 'Priority'],
      ['assignees', 'Assignees'],
      ['tags', 'Tags'],
      ['category', 'Category'],
      ['template', 'Template'],
      ['asset', 'Asset'],
      ['component_type', 'Component'],
      ['start_date', 'Start date'],
      ['due_date', 'Due date'],
      ['last_activity_at', 'Last activity'],
    ].map((item) => {
      return {
        key: item[0],
        label: item[1],
      };
    }), 'key'),
    value_options: keyBy([
      ['tasks_count', 'Tasks count'],
      ['assignees', 'Assignees count'],
      ['overdue_tasks_percent', 'Avg % overdue tasks'],
      ['overdue_tasks', 'Overdue tasks'],
      ['checklist_percent', 'Checklist % progress'],
      ['days_overdue', 'Days overdue'],
    ].map((item) => {
      return {
        key: item[0],
        label: item[1],
      };
    }), 'key'),
    data_set: false,
  }),
  getters: {
    tasks_schema: (state) => {
      const { $t } = useCommonImports();
      const getOptions = (options, keys = ['label']) => options.map(option => ({
        ...option,
        ...keys.reduce((acc, key) => ({
          ...acc,
          [key]: $t(option[key]),
        }), {}),
      }));
      if (!state.data_set)
        return {};

      return {
        type: {
          type: 'select',
          items: getOptions(state.type_options, ['label', 'description']),
          search: true,
          native: false,
          label: $t('Type'),
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: [
            'required',
          ],
          default: state.tasks_configuration.type || state.type_options[0].value,
          slots: {
            option: SelectOptionTemplate,
          },
        },
        field: {
          type: 'select',
          items: getOptions(Object.values(state.field_options)),
          search: true,
          native: false,
          label: $t('Field'),
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          valueProp: 'key',
          object: true,
          conditions: [
            [
              'type',
              'in',
              [
                'horizontal_bar',
                'vertical_graph',
                'donut',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'horizontal_bar',
                    'vertical_graph',
                    'donut',
                  ],
                ],
              ],
            },
          ],
          default: state.tasks_configuration.field || Object.values(state.field_options)[0],
        },
        value: {
          type: 'select',
          items: getOptions(Object.values(state.value_options)),
          search: true,
          native: false,
          label: $t('Value'),
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          object: true,
          valueProp: 'key',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'horizontal_bar',
                'vertical_graph',
                'donut',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'horizontal_bar',
                    'vertical_graph',
                    'donut',
                  ],
                ],
              ],
            },
          ],
          default: state.tasks_configuration.value || Object.values(state.value_options)[0],
        },
        breakdown: {
          type: 'select',
          items: getOptions(Object.values(state.breakdown_options)),
          search: true,
          native: false,
          label: $t('Breakdown by'),
          canClear: false,
          valueProp: 'key',
          object: true,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'horizontal_bar',
                'vertical_graph',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'horizontal_bar',
                    'vertical_graph',
                    'donut',
                  ],
                ],
              ],
            },
          ],
          default: state.tasks_configuration.breakdown || Object.values(state.breakdown_options)[0],
        },
        sub_tasks: {
          type: 'checkbox',
          text: $t('Include subtasks'),
          default: state.tasks_configuration.sub_tasks || false,
        },
        scope: {
          type: 'checkbox',
          text: $t('Make report data specific to the viewer'),
          default: state.tasks_configuration.scope || false,
          conditions: [
            [
              'type',
              'not_in',
              [
                'tasks_list',
              ],
            ],
          ],
        },
        module: {
          type: 'hidden',
          default: 'tasks',
        },
      };
    },
  },
  actions: {
    update_tasks_configuration(data) {
      this.tasks_configuration = { ...data };
    },
    getMinW() {
      return this.tasks_configuration.type === 'tasks_list' ? 6 : 4;
    },
    update_relative_date_filters(filters) {
      const filters_payload = { ...filters.filters };
      if (filters?.report_filters?.length) {
        filters.report_filters.forEach((f) => {
          if (isDateIntervalOperator(f.operator)) {
            const [first_day_date, last_day_date] = getDateInterval(f.operator);

            filters_payload[`${f.filter_type}_start`] = first_day_date.toISOString();
            filters_payload[`${f.filter_type}_end`] = last_day_date.toISOString();
          }
        });
      }

      return {
        filters: filters_payload,
        report_filters: filters.report_filters,
      };
    },
    parse_tasks_form_to_server_format(widget_data) {
      if (!Object.keys(widget_data || {}).length)
        return null;

      const authStore = useAuthStore();
      // update date filters at runtime, ex 'this_month'
      const filters = this.update_relative_date_filters(widget_data.filters || {});

      const payload = {
        properties: {
          source: {
            type: 'tasks',
            service: 'tasks',
          },
          report_filters: filters?.report_filters || [],
          config: widget_data.chart_config || {},
          columns_widths: widget_data.columns_widths || {},
        },
        filters: {
          organization: authStore.current_organization?.uid,
          asset_uid: widget_data.asset_id,
          ...filters?.filters || {},
          sub_tasks: widget_data.sub_tasks,
        },
        filter_rules: widget_data?.filter_rules || widget_data.filters?.filter_rules || [],
        x: widget_data.field,
        y: widget_data.value,
        ...((widget_data.breakdown?.key !== 'none'
          && !['tasks_list', 'donut'].includes(widget_data.type))
          ? { group: widget_data.breakdown }
          : {}),
        chart: widget_data.type,
        scope: widget_data.scope ? 'user' : 'organization',
        minW: this.getMinW(),
      };

      return payload;
    },
    get_initial_filters(widget_data) {
      const filters = {};
      filters.report_filters = widget_data.properties?.report_filters || [];

      const formatted_filters = omit(
        widget_data.filters,
        ['organization', 'asset_uid', 'sub_tasks'],
      );

      filters.filters = formatted_filters;

      return filters;
    },
    unparse_tasks_form_to_server_format(widget_data) {
      if (!Object.keys(widget_data || {}).length)
        return null;

      const data = {};
      data.name = widget_data.properties?.name || 'Untitled';
      data.type = widget_data.chart;
      data.field = widget_data.x || this.field_options[0];
      data.value = widget_data.y || this.value_options[0];
      data.breakdown = widget_data.group || this.breakdown_options[0];
      data.scope = widget_data.scope === 'user';
      data.sub_tasks = widget_data.filters?.sub_tasks || false;
      data.chart_config = widget_data.properties?.config || null;
      data.filters = this.get_initial_filters(widget_data);
      data.filter_rules = widget_data.filter_rules || [];
      data.asset_id = widget_data.filters?.asset_uid || [];
      data.columns_widths = widget_data.properties?.columns_widths || {};
      data.module = 'tasks';

      return data;
    },
    set_data() {
      const dashboard_store = useDashboardStore();
      const widget_configuration = dashboard_store.widget_configuration;

      if (
        !Object.keys(widget_configuration || {}).length
        || widget_configuration?.module !== 'tasks'
      ) {
        this.data_set = true;
        this.tasks_configuration = {
          ...this.tasks_configuration,
          asset_id: dashboard_store.widget_asset,
        };
        return;
      }

      this.tasks_configuration = { ...widget_configuration };

      this.data_set = true;
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDashboardTasksStore, import.meta.hot));
