<script setup>
import Cookies from 'universal-cookie';
import HawkMenu from '~/common/components/molecules/hawk-menu.vue';
import { useI18nStore } from '~/common/stores/i18n.store';

defineProps({
  variant: { type: String, default: '' },
});
const $t = inject('$t');
const cookies = new Cookies();
const { set_language } = useI18nStore();

const LANGUAGES = [
  { title: 'English', label: 'EN - English', uid: 'en', on_click: () => { onChangeLanguage('en'); } },
  { title: '日本語', label: 'JA - 日本語', uid: 'ja', on_click: () => { onChangeLanguage('ja'); } },
  { title: 'Español', label: 'ES - Español', uid: 'es', on_click: () => { onChangeLanguage('es'); } },
  { title: 'Português', label: 'PT - português', uid: 'pt', on_click: () => { onChangeLanguage('pt'); } },
  { title: 'German', label: 'DE - German', uid: 'de', on_click: () => { onChangeLanguage('de'); } },
];

function onChangeLanguage(language) {
  set_language(language);
  location.reload();
}

function getActiveLanguageLabel(key) {
  const active_language = cookies.get('lang') || 'en';
  return LANGUAGES.find(lang => lang.uid === active_language)?.[key];
}
</script>

<template>
  <HawkMenu :items="LANGUAGES" additional_trigger_classes="!ring-0">
    <template #trigger="{ open }">
      <div v-if="variant === 'full'" class="flex items-center gap-2">
        <IconHawkGlobeFour class="w-4 h-4" />
        <HawkButton v-tippy="{ content: $t('Switch language'), placement: 'bottom' }" type="link" class="flex">
          <span class="text-sm">
            {{ getActiveLanguageLabel('title') }}
          </span>
          <IconHawkChevronUp v-if="open" />
          <IconHawkChevronDown v-else />
        </HawkButton>
      </div>

      <HawkButton v-else v-tippy="{ content: $t('Switch language'), placement: 'bottom' }" icon type="text" size="md" class="flex hover:bg-gray-900">
        <span class="text-sm uppercase text-white">
          {{ getActiveLanguageLabel('uid') }}
        </span>
      </HawkButton>
    </template>
  </HawkMenu>
</template>
