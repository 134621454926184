<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { nextTick } from 'vue';
import { useModal } from 'vue-final-modal';

import { useRoute } from 'vue-router';

import { useAuthStore } from '~/auth/stores/auth.store';

import HawkConfirmationPopup from '~/common/components/organisms/hawk-confirmation-popup.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';

import CustomExportConfirmationPopup from '~/tasks/components/organisms/custom-export/custom-export-confirmation-popup.vue';
import TaskActions from '~/tasks/components/organisms/tasks-actions.vue';

import { bulkTasksExporter } from '~/tasks/composables/task-composables.js';

const props = defineProps({
  task_store: {
    type: Object,
    required: true,
  },
  selected_tasks: {
    type: Array,
    default: () => ([]),
  },
  bulk_actions_config: {
    type: Object,
    default: () => ({
      prepend: false,
      include: ['bulk-update', 'archive', 'export-as-csv', 'export-as-pdf', 'delete'],
      additional_options: [],
    }),
  },
  options: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['close', 'deleteTasks', 'exportTasksAsPdf', 'archiveTasks', 'exportTasksAsCsv']);

const $t = inject('$t');
const $services = inject('$services');
const $toast = inject('$toast');

const route = useRoute();

const auth_store = useAuthStore();
const current_organization = auth_store.current_organization;

const show_dropdown = ref(false);
const is_pdf_exporting = ref(false);
const custom_export_payload = ref({});

/* --------- TASK BULK ACTIONS --------- */
const { open: openBulkActionsPopup, close: closeBulkActionsPopup, patchOptions: patchBulkActionsPopup } = useModal({
  component: TaskActions,
});
function bulkUpdateHandler() {
  patchBulkActionsPopup({
    attrs: {
      task_store: props.task_store,
      tasks: props.selected_tasks,
      asset_id: route.params.asset_id,
      onClose() {
        closeBulkActionsPopup();
      },
    },
  });
  openBulkActionsPopup();
}

/* --------- TASK BULK DELETE --------- */
const { open: openDeletePopup, close: closeDeletePopup, patchOptions: patchDeleteForm } = useModal({
  component: HawkDeletePopup,
});
function bulkDeleteHandler() {
  patchDeleteForm(
    {
      attrs: {
        header: $t('Delete Tasks'),
        content: $t('Are you sure you want to delete') + ` ${props.selected_tasks.length} ` + $t('tasks? This action cannot be undone'),
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            const selected_tasks_uids = props.selected_tasks.map(task => task.uid);
            await props.task_store.remove_tasks(selected_tasks_uids);
            $toast({
              title: $t('Tasks deleted successfully'),
              text: $t('Your changes have been saved and your tasks are removed from the list'),
              type: 'success',
              position: 'bottom-right',
            });
            emit('deleteTasks');
            closeDeletePopup();
          }
          catch {
            $toast({
              title: $t('Something went wrong'),
              text: $t('Please try again'),
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

async function exportPdfs() {
  const uids = props.selected_tasks.map(t => t.uid);
  await bulkTasksExporter(uids, () => is_pdf_exporting.value = false, custom_export_payload.value);
  props.task_store.task_track_events('Exported', { output_format: 'PDF', count: uids.length, with_summary: !!custom_export_payload.value.is_custom }, '', ['view']);
  custom_export_payload.value = {};
}

function bulkPdfExportHandler(payload = {}) {
  if (is_pdf_exporting.value)
    is_pdf_exporting.value = false;
  nextTick(() => {
    is_pdf_exporting.value = true;
    custom_export_payload.value = payload;
  });
}

/* ------- TASK CUSTOM EXPORT ------- */
const { open: openCustomExportConfirmationPopup, close: closeCustomExportConfirmationPopup, patchOptions: patchCustomExportConfirmationPopup } = useModal({
  component: CustomExportConfirmationPopup,
});
function customBulkTaskPdfExportHandler() {
  patchCustomExportConfirmationPopup(
    {
      attrs: {
        export_method: bulkPdfExportHandler,
        onClose() {
          closeCustomExportConfirmationPopup();
        },
      },
    },
  );
  openCustomExportConfirmationPopup();
}

/* ------- TASK ARCHIVE ------- */
const { open: openConfirmationPopup, close: closeConfirmationPopup, patchOptions: patchConfirmationPopup } = useModal({
  component: HawkConfirmationPopup,
});
function bulkArchiveHandler(archive = true) {
  patchConfirmationPopup(
    {
      attrs: {
        header: archive ? $t('Archive Tasks') : $t('Unarchive Tasks'),
        content: `${$t(`Are you sure you want to ${archive ? 'archive' : 'unarchive'}`)}` + ` ${props.selected_tasks.length} ` + `${$t('tasks')}?`,
        onClose() {
          closeConfirmationPopup();
        },
        onConfirm: async () => {
          const selected_tasks_uids = props.selected_tasks.map(task => task.uid);
          try {
            await props.task_store.archive_tasks(selected_tasks_uids, archive);
            if (selected_tasks_uids.length && archive)
              props.task_store.task_track_events('Archived', { mode: selected_tasks_uids.length > 1 ? 'Bulk' : 'Single', count: selected_tasks_uids.length }, selected_tasks_uids[0]);
            $toast({
              title: `${$t(`Tasks ${archive ? 'archived' : 'unarchived'} successfully`)}`,
              text: `${$t(`Your changes have been saved and your tasks are ${archive ? 'archived' : 'unarchived'} from the list`)}`,
              type: 'success',
              position: 'bottom-right',
            });
            closeConfirmationPopup();
            emit('archiveTasks');
          }
          catch {
            $toast({
              title: $t('Something went wrong'),
              text: $t('Please try again'),
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openConfirmationPopup();
}

async function bulkCsvExportHandler() {
  const uids = props.selected_tasks.map(task => task.uid);
  const payload = {
    fields: [
      'name',
      'status',
      'tags',
      'assignee',
      'due_date',
      'priority',
      'attachments',
    ],
    filters: {
      organization: current_organization.uid,
      taskUID: uids,
      ...props.task_store.filters,
    },
  };
  try {
    const { data } = await $services.tasks.generate_csv({
      body: payload,
    });
    const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    let csvURL = null;
    if (navigator.msSaveBlob)
      csvURL = navigator.msSaveBlob(csvData, 'download.csv');
    else
      csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'download.csv');
    tempLink.click();
    props.task_store.task_track_events('Exported', { output_format: 'CSV', count: props.selected_tasks.length, with_summary: false }, '', ['view']);
    emit('exportTasksAsCsv');
  }
  catch {
    $toast({
      title: $t('Something went wrong'),
      text: $t('Please try again'),
      type: 'error',
      position: 'bottom-right',
    });
  }
}

const bulk_action_collection = computed(() => {
  const archived_view = !!props.task_store.filters?.archived;
  return {
    'archive': {
      name: 'archive',
      label: $t(archived_view ? 'Unarchive' : 'Archive'),
      on_click: () => bulkArchiveHandler(!archived_view),
    },
    'bulk-update': {
      name: 'bulk-update',
      label: $t('Bulk Update'),
      on_click: bulkUpdateHandler,
    },
    'delete': {
      name: 'delete',
      label: $t('Delete'),
      on_click: bulkDeleteHandler,
    },
    'export-as-csv': {
      name: 'export-as-csv',
      label: $t('Export CSV'),
      on_click: bulkCsvExportHandler,
    },
    'export-as-pdf': {
      name: 'export-as-pdf',
      label: $t('Export PDF'),
      disabled: props.selected_tasks.length > 100,
      tooltip: props.selected_tasks.length > 100 ? $t('Can not export more than 100 tasks') : '',
      on_click: () => { auth_store.check_split('summary_tasks_export') ? customBulkTaskPdfExportHandler() : bulkPdfExportHandler(); },
    },
  };
});

const bulk_action_options = computed(() => {
  const options_to_include = props.bulk_actions_config?.include || ['bulk-update', 'archive', 'export-as-csv', 'export-as-pdf', 'delete'];
  let options = [];
  options_to_include.forEach((option_name, idx) => {
    if (bulk_action_collection.value[option_name] && ((option_name === 'export-as-pdf' && auth_store.check_split('tasks_export_pdf')) || option_name !== 'export-as-pdf'))
      options.push({ uid: idx, ...bulk_action_collection.value[option_name] });
  });

  if (props.bulk_actions_config?.additional_options?.length)
    options = props.bulk_actions_config?.prepend ? [...props.bulk_actions_config.additional_options, ...options] : [...options, ...props.bulk_actions_config.additional_options];
  return options;
});
</script>

<template>
  <div class="flex items-center">
    <hawk-menu
      v-bind="options"
      :items="bulk_action_options"
      :disabled="!selected_tasks?.length"
      @open="show_dropdown = true"
      @close="() => {
        show_dropdown = false;
        emit('close');
      }"
    >
      <template #trigger>
        <slot name="trigger">
          <hawk-button type="outlined">
            {{ $t('Actions') }}
            <IconHawkChevronDown
              class="text-white transition-transform"
              :class="{ 'rotate-180': show_dropdown }"
            />
          </hawk-button>
        </slot>
      </template>
    </hawk-menu>
  </div>
  <HawkExportToast v-if="is_pdf_exporting" :submit="exportPdfs" @cancel="is_pdf_exporting = false" @close="is_pdf_exporting = false" />
</template>
