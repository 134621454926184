import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export function useTableSummaryValues(section_summary, fields, summary_field_value, options) {
  let parser = null;

  async function initializeParser() {
    if (!parser) {
      const { Parser } = await import('hot-formula-parser');
      parser = new Parser();
      setHotParserVariables(parser);
      loadParserEvents(parser);
    }
  }

  const field_value_map = computed(() => (fields.value || fields).reduce((map, field) => {
    map[field.uid] = field;
    return map;
  }, {}));

  function footerFunction(field_uid) {
    if (!parser)
      return;
    if (section_summary.value?.summary_type === 'column_summary') {
      if (options.disable_summary_calculation && summary_field_value?.type === 'column_summary' && summary_field_value?.values?.[field_uid]) {
        return summary_field_value.values[field_uid];
      }
      const summary_data = section_summary.value?.data[field_uid];
      if (summary_data?.footer) {
        const field = field_value_map.value[field_uid];
        const values = field.properties?.value?.map(val => formatData(field, val)) || [];
        const _values = field.properties?._value?.map(val => formatData(field, val)) || [];
        const final_values = values.length === 0 ? _values : values;
        if (summary_data?.footer === 'Count') {
          return final_values?.flat()?.filter(value => !!value)?.length;
        }
        const formula = parser.parse(`${summary_data.footer}(${final_values.toString()})`);
        return Number(formula.result) ? Number(formula.result).toFixed(2) : formula.result;
      }
    }
    else if (section_summary.value?.summary_type === 'detailed_summary') {
      if (options.disable_summary_calculation && summary_field_value?.type === 'detailed_summary' && summary_field_value?.values) {
        return summary_field_value.values;
      }
      const summary_data = section_summary.value.data;
      const result = {};
      summary_data.forEach((data) => {
        const formula = parser.parse(data.formula);
        result[data.name] = Number(formula.result) ? Number(formula.result).toFixed(2) : 0;
      });
      return result;
    }
    return '';
  }

  function setHotParserVariables(parser) {
    function getNextChar(char) {
      return String.fromCharCode(char.charCodeAt(0) + 1);
    }
    let char = 'A';
    (fields.value || fields).forEach((field) => {
      parser.setVariable(char, field.uid);
      char = getNextChar(char);
    });
  }

  function formatDateTime(value, field) {
    if (!value)
      return null;

    if (field?.config?.type !== 'time')
      return value;

    const time = dayjs(value).format('hh:mm a');
    const dateTime = dayjs(`01-01-2000 ${time}`, 'DD-MM-YYYY hh:mm a').toDate().toISOString();
    return dateTime;
  }

  function formatData(field, value) {
    if (field.type === 'multi_text')
      return value ? value.length : 0;
    else if (
      field?.type === 'number' || field?.type === 'decimal' || field?.config?.type === 'formula'
    ) return Number(value) ? +value : 0;
    else if (field?.type === 'date_time')
      return formatDateTime(value, field);
    else return (Number(value) ? +value : value);
  }

  function loadParserEvents(parser) {
    parser.on('callVariable', (name, done) => {
      const field_uid = parser.getVariable(name);
      const field = field_value_map.value[field_uid];
      const value = field.properties?.value?.map(val => formatData(field, val));
      done(value || []);
    });
  }

  return { footerFunction, setHotParserVariables, formatData, initializeParser };
}
