<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useRoute } from 'vue-router';
import TaskTemplateDropdown from '~/tasks/components/atoms/task-template-dropdown.vue';

import { useChecklistStore } from '~/tasks/store/checklists.store';

const props = defineProps({
  task_data: {
    type: Object,
    default: () => ({}),
  },
  on_submit: {
    type: Function,
    required: true,
  },
  on_cancel: {
    type: Function,
    required: false,
    default: () => ({}),
  },
  task_type: {
    type: String,
    default: 'task',
  },
  allow_multi_task_create: {
    type: Boolean,
    default: false,
  },
  prefix_feature_name: {
    type: Boolean,
    default: false,
  },
  is_name_required: {
    type: Boolean,
    default: false,
  },
  parent_task: {
    type: Object,
    default: () => ({}),
  },
  modal_options: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['close']);

const route = useRoute();

const form$ = ref(null);
const form = ref(null);
const loading = ref(false);

const checklist_store = useChecklistStore('subtask_checklists');
checklist_store.set_checklists();

const create_multiple = ref(false);
const task_names_list = ref([]);

// Popup save
async function handleSave() {
  loading.value = true;
  const assignees = Array.isArray(form.value.assignees) ? form.value.assignees.map(obj => obj.uid) : [];
  const form_data = {
    ...props.task_data,
    ...form.value,
    assignees,
  };
  if (props.allow_multi_task_create && create_multiple.value) {
    const payload = task_names_list.value.reduce((acc, name) => {
      acc.push({ ...form_data, name });
      return acc;
    }, []);
    const data = {
      payload,
      has_multiple_tasks: true,
    };
    await props.on_submit(data);
    loading.value = false;
    emit('close');
  }
  else {
    const payload = form_data;
    if (!payload.name)
      delete payload.name;
    await props.on_submit(payload);
    loading.value = false;
    emit('close');
  }
}
function onFormMounted(el$) {
  if (props.task_data)
    el$.value?.load(props.task_data, true);
  if (props.prefix_feature_name) {
    form.value.prefix_feature_name = true;
    el$.value?.load(form.value, true);
  }
  el$.form$.elements$.name.focus();
};
function setCreatedCategory(e) {
  form$.value.elements$.category.load(e.uid);
}
function setCreatedTag(e) {
  form$.value.elements$.tags.load([...form.value.tags, e.uid]);
}
function reInitValidation() {
  form$.value.elements$.name.reinitValidation();
}
</script>

<template>
  <hawk-modal-container id="task-form-modal-container" :options="modal_options" @close="on_cancel()">
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      :sync="true"
      :format-load="(data) => data"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :should_validate_on_mount="false" :display-errors="false"
      class="grid grid-rows-1 grid-flow-col gap-4"
      :endpoint="handleSave"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close'), on_cancel()">
          <template #title>
            <hawk-breadcrumbs
              v-if="task_type === 'subtask'"
              :items="[{ label: parent_task?.name, uid: parent_task?.uid, disabled: true }, { label: $t('New Subtask'), disabled: true }]"
            />
            <slot v-if="$slots.title" name="title" />
            <div v-else class="flex items-center">
              {{ $t(`New ${task_type}`) }}
            </div>
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content>
          <!-- Name -->
          <hawk-multiline-input
            class="mb-4"
            message_text="tasks"
            :options="{
              name: 'name',
              rules: !is_name_required && (form?.template_uid || form?.prefix_feature_name) ? '' : 'required',
            }"
            @clear="() => { form$?.elements$?.name.clear(); task_names_list = []; create_multiple = false; }"
            @create="(items_list) => { create_multiple = true; task_names_list = items_list; }"
          />

          <!-- Category -->
          <hawk-category-input
            class="mb-4"
            :options="{
              name: 'category',
              label: $t('Category'),
              placeholder: $t('Select Category'),
              create: true,
              from: 'Tasks',
            }"
            :setter_function="setCreatedCategory"
            :asset_id="route.params.asset_id"
          />

          <!-- Assignees -->
          <hawk-assignee-input
            class="mb-6"
            :options="{
              name: 'assignees',
              label: $t('Assignees'),
              has_teams: true,
              placeholder: $t('Select Assignees'),
              appendTo: '#task-form-modal-container',
            }"
            :asset_id="route.params.asset_id"
            :multi="true"
            :format_load="true"
          />
          <CheckboxElement
            class="mb-4 text-primary font-semibold"
            name="advance_options"
            :add-class="{
              input: 'hidden',
            }"
          >
            <div class="flex items-center text-[#004EEB] ml-[-0.5rem]">
              <IconHawkChevronRight v-if="form && !form.advance_options" class="w-5 text-primary-700" />
              <IconHawkChevronDown v-else class="w-5 text-primary-700" />
              {{ $t('Advanced options') }}
            </div>
          </CheckboxElement>

          <!-- Start Date -->
          <DateTimeElement
            :key="form?.due_date"
            class="mb-4"
            name="start_date"
            :label="$t('Start Date')"
            :conditions="[
              ['advance_options', true],
            ]"
            :options="{
              format: 'dd/MM/yyyy',
              placeholder: $t('Select Start Date'),
              startTime: { hours: 0, minutes: 0 },
              maxDate: form$?.data?.due_date ? new Date(form$.data.due_date) : null,
            }"
          />

          <!-- Due Date -->
          <DateTimeElement
            :key="form?.start_date"
            class="mb-4"
            name="due_date"
            :label="$t('Due Date')"
            :conditions="[
              ['advance_options', true],
            ]"
            :options="{
              format: 'dd/MM/yyyy',
              placeholder: $t('Select Due Date'),
              startTime: { hours: 23, minutes: 59, seconds: 59 },
              minDate: form$?.data?.start_date ? new Date(form$?.data?.start_date) : null,
            }"
          />

          <!-- Tags -->
          <hawk-tags-input
            class="mb-4"
            :options="{
              name: 'tags',
              label: $t('tags'),
              placeholder: $t('Select Tags'),
              conditions: [
                ['advance_options', true],
              ],
              create: true,
            }"
            :setter_function="setCreatedTag"
          />

          <!-- Task Template -->
          <TaskTemplateDropdown
            v-if="task_type === 'task'"
            class="mb-4"
            :options="{
              conditions: [
                ['advance_options', true],
              ],
            }"
            :asset_id="parent_task?.target_element?.asset || route.params.asset_id"
            :show_only_org_templates="!(parent_task?.target_element?.asset || route.params.asset_id)"
            @change="reInitValidation"
          />
          <!-- Prefix Feature Name -->
          <CheckboxElement
            v-if="prefix_feature_name" name="prefix_feature_name"
            :conditions="[
              ['advance_options', true],
            ]"
            :default="true"
            :label="$t('Prefix feature name')"
          />
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template v-if="$slots.left_footer" #left>
            <slot name="left_footer" />
          </template>
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button class="mr-5" type="outlined" @click="emit('close'), on_cancel()">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :loading="loading" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
