<script setup>
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import formBulkUpdate from '~/forms/components/form-bulk-update.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useExportPDF } from '~/forms/composables/form-detail-composable';
import { FORM_PERMISSIONS } from '~/forms/constants';

const props = defineProps({
  forms_store: {
    type: Object,
    required: true,
  },
  selected_forms: {
    type: Array,
    required: true,
  },
  options: {
    type: Object,
    default: () => {},
  },
});
const emit = defineEmits(['deleteForms']);

const $t = inject('$t');
const $toast = inject('$toast');

const is_pdf_exporting = ref(false);

const route = useRoute();
const auth_store = useAuthStore();
const { formsPDFExportHandler } = useExportPDF();

const { open, close } = useModal({
  component: formBulkUpdate,
  attrs: {
    forms_store: props.forms_store,
    onClose() {
      close();
    },
    selected_forms: props.selected_forms,
  },
});
async function formBulkDeleteHandler() {
  try {
    const selected_forms_uids = props.selected_forms.map(form => form.uid);
    await props.forms_store.remove_forms(selected_forms_uids);
    emit('deleteForms');
  }
  catch (error) {
    logger.log(error);
  }
}
function deletePopup() {
  const form_text = props.selected_forms.length > 1 ? $t('forms') : $t('form');
  const content = `${$t('Are you sure you want to delete')} ${props.selected_forms.length} ${form_text} ? ${$t('This action cannot be undone.')}`;
  const { open: openDeletePopup, close: closeDeletePopup } = useModal({
    component: HawkDeletePopup,
    attrs: {
      header: $t('Delete Forms'),
      content,
      onClose() {
        closeDeletePopup();
      },
      confirm: async () => {
        try {
          await formBulkDeleteHandler();
          closeDeletePopup();
        }
        catch (err) {
          logger.log(err);
          $toast({
            title: 'Something went wrong',
            text: 'Please try again',
            type: 'error',
          });
        }
      },
    },
  });
  openDeletePopup();
}
function exportCTAHandler() {
  if (is_pdf_exporting.value)
    is_pdf_exporting.value = false;
  nextTick(() => {
    is_pdf_exporting.value = true;
  });
}
const bulk_action_options = [
  { uid: 0, label: $t('Bulk Update'), on_click: open },
  ...(auth_store.check_permission(FORM_PERMISSIONS.V2_MODIFY_FORMS, route.params.asset_id)
    ? [
        { uid: 1, label: $t('Delete'), on_click: deletePopup },
      ]
    : []),
  ...(auth_store.check_split('forms_export_pdf')
    ? [
        { uid: 2, label: $t('Export PDF'), on_click: exportCTAHandler, disabled: props.selected_forms.length > 100, tooltip: props.selected_forms.length > 100 ? $t('Can not export more than 100 forms') : '' },
      ]
    : []
  ),
];
</script>

<template>
  <div class="flex items-center">
    <hawk-menu
      v-bind="options"
      :items="bulk_action_options"
      :disabled="!selected_forms?.length"
      @open="show_dropdown = true"
      @close="() => {
        show_dropdown = false;
        $emit('close')
      }"
    >
      <template #trigger>
        <slot name="trigger">
          <hawk-button type="outlined">
            {{ $t('Actions') }}
            <IconHawkChevronDown
              class="text-white transition-transform"
              :class="{ 'rotate-180': show_dropdown }"
            />
          </hawk-button>
        </slot>
      </template>
    </hawk-menu>
  </div>
  <HawkExportToast
    v-if="is_pdf_exporting"
    v-bind="{
      ...(selected_forms.length > 1 ? {
        progress_text: $t('Exporting PDF'),
        completed_text: $t('Export request received'),
        completed_subtitle: $t('You will receive an email to download once the export is completed.'),
      } : {}),
    }"
    :submit="() => formsPDFExportHandler(selected_forms, route.params?.template_uid, () => is_pdf_exporting = false, props.forms_store)"
    @cancel="is_pdf_exporting = false"
    @close="is_pdf_exporting = false"
  />
</template>
