<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { saveAs } from 'file-saver';
import { formatDropdownRadioValue, formatLabelsCheckboxValue, formatMoneyValue, formatNumberValue, formatPercentageValue, formatPlannedActualValue } from '~/common/components/molecules/hawk-custom-field-value-render/custom-field-formatter.js';

import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  field_value: {
    type: Object,
    default: () => {},
  },
  field_selector: {
    type: String,
    default: 'uid',
  },
});

const common_store = useCommonStore();

const state = reactive({
  file_downloading: null,
});

const field = computed(() => common_store.assets_custom_fields.find(item => item.uid === props?.field_value?.[props.field_selector]));

function getFieldValue() {
  if (!props.field_value?.value)
    return '-';

  switch (field.value.type) {
    case 'percentage':
      return formatPercentageValue(props.field_value?.value);
    case 'planned_actual':{
      return formatPlannedActualValue(field.value, props.field_value?.value);
    }
    case 'dropdown':
    case 'radio':
      return formatDropdownRadioValue(field.value, props.field_value?.value);
    case 'number':
      return formatNumberValue(field.value, props.field_value?.value);
    case 'money':
      return formatMoneyValue(field.value, props.field_value?.value);
    case 'labels':
    case 'checkbox':{
      return formatLabelsCheckboxValue(field.value, props.field_value?.value);
    }
    default:
      return props?.field_value?.value || '-';
  }
}

async function downloadAttachment(file) {
  state.file_downloading = file.id;
  const response = await fetch(file?.url);
  const response_blob = await response.blob();
  saveAs(response_blob, file?.file_name);
  state.file_downloading = null;
}
</script>

<template>
  <div class="group">
    <div v-if="field?.type === 'files'" class="min-w-48">
      <HawkAttachmentsList
        :items="field_value?.value"
        :can_delete="false"
        variant="mini"
        :is_loading_uid="state.file_downloading"
        :filename_truncate_length="14"
        @download="downloadAttachment"
      />
    </div>
    <div v-else-if="field?.type === 'datetime'">
      <div v-if="field_value.value">
        {{ $date(field_value.value, 'DD MMM YYYY, HH:MM') }}
      </div>
      <div v-else>
        -
      </div>
    </div>
    <div v-else-if="field?.type === 'date'">
      <div v-if="field_value.value">
        {{ $date(field_value.value, 'DD MMM YYYY') }}
      </div>
      <div v-else>
        -
      </div>
    </div>
    <div v-else-if="field?.type === 'members'">
      <HawkMembers :members="field_value.value" type="badge" />
    </div>
    <div v-else-if="field?.type === 'date_range'">
      <div v-if="field_value?.value?.start && field_value?.value?.end" class="flex items-center flex-wrap">
        <span>{{ $date(field_value?.value?.start, 'DD MMMM YYYY') }}</span>
        <IconHawkArrowNarrowRight class="mx-2" />
        <span>{{ $date(field_value?.value?.end, 'DD MMMM YYYY') }}</span>
        ({{ field_value?.value?.duration }} {{ $t('days') }})
      </div>
      <div v-else>
        -
      </div>
    </div>
    <div v-else class="break-all">
      {{ getFieldValue() }}
    </div>
  </div>
</template>
